"use client";
import {axiosInstance} from "@/app/api";
import {IStatusType} from "@/libs/enums/live";

const onGetTrackingStatus = async (
  vehicleId: string,
  institutionUserId: string,
  key: number
): Promise<IRouteTracking> => {
  const body: IVehicleTrackingRequest = {
    vehicleId: vehicleId,
    userid: institutionUserId,
    offTrip: false,
    returnBackKey: key
  };
  return await axiosInstance()
    .post(`/api/track`, body)
    .then((response: any) => {
      const neoresponse: NeoResponse<IRouteTracking> = response.data;
      if (neoresponse?.status === "success") {
        const result =
          neoresponse?.data?.routeTrackings &&
          neoresponse?.data.routeTrackings?.length > 0
            ? neoresponse?.data?.routeTrackings[0]
            : null;

        if (key == -1) {
          if (
            result &&
            result?.status == IStatusType.ONTRIP &&
            result?.onTripData?.refRouteId
          ) {
            key = result?.returnBackKey;
            return onGetRouteReference(
              result?.onTripData?.refRouteId,
              neoresponse,
              key
            );
          }
        }
        key = result?.returnBackKey!;
        return getCoordinates(neoresponse, key);
      } else {
        return getCoordinates(neoresponse, key);
      }
    })
    .catch((error: any) => {
      // console.log("cached coordinates");
      // console.log(error);
      return {
        returnBackKey: key,
        routeTrackings: [],
        userBoardings: [],
        refRouteDetails: [],
        locations: [],
        clearRouteData: false,
        trackerApi: "failure",
        referenceApi: "unknown",
        trackerStatus: 1 //
      } as IRouteTracking;
    });
};

const onGetRouteReference = async (
  routeId: number,
  routeTrackingResponse: NeoResponse<IRouteTracking>,
  key: number
): Promise<IRouteTracking> => {
  const body = {
    routeId: routeId
  };
  const coordinates = getCoordinates(routeTrackingResponse, key);
  return await axiosInstance()
    .post(`/api/route/reference`, body)
    .then((response: any) => {
      const neoresponse: NeoResponse<IReferenceRoutes> = response.data;
      return {
        ...coordinates,
        userBoardings:
          neoresponse?.status === "success"
            ? neoresponse.data?.userBoardings?.map(x => ({
                lat: x?.lat,
                lng: x?.lon,
                type: x?.type,
                bpName: x?.bpName,
                users: x?.users
              })) || []
            : [],
        refRouteDetails:
          neoresponse?.status === "success"
            ? neoresponse.data?.refRouteDetails?.map(x => ({
                lat: x?.lat,
                lng: x?.lon
              })) || []
            : [],
        referenceApi: neoresponse?.status === "success" ? "success" : "unknown"
      } as IRouteTracking;
    })
    .catch((error: any) => {
      return {
        ...coordinates,
        referenceApi: "failure",
        userBoardings: [],
        refRouteDetails: []
      } as IRouteTracking;
    });
};

const onGetPlayback = async (data: Details, key: number): Promise<any> => {
  const portalUserId = "TYbTiDU";
  const body = {
    clientId: portalUserId,
    fromDate: 1706416200000,
    toDate: 1706423400000
  };

  const url = `/api/vehicle/history`;
  return axiosInstance()
    .post(`/api/vehicle/history`, body)
    .then((response: any) => {
      if (response.data?.status === "success") {
        return [];
      }
    })
    .catch((error: any) => {});
};

const onGetVehicleRoutes = async (request: getRouteProp): Promise<any> => {
  const url = `/api/v2/transport/tripreport`;
  return axiosInstance()
    .put(url, request)
    .then((response: any) => {
      const neoresponse: NeoResponse<vehicleRouteResProp> = response.data;
      return {
        trips:
          neoresponse?.status === "success"
            ? neoresponse.data?.trips?.map(x => ({
                id: x?.id,
                name: x?.name,
                starttime: x?.starttime,
                endtime: x?.endtime
              })) || []
            : []
      };
    })
    .catch((error: any) => {});
};
//processing latest location
const getCoordinates = (
  response: NeoResponse<IRouteTracking>,
  key: number
): IRouteTracking => {
  const routeTracking: IRouteTracking = {} as IRouteTracking;
  routeTracking.locations = [];
  routeTracking.trackerApi = "unknown";
  routeTracking.returnBackKey = key;
  routeTracking.routeTrackings = [];
  routeTracking.clearRouteData = false;
  routeTracking.trackerStatus = 1; //offline
  if (response.status === "success") {
    routeTracking.routeTrackings = response?.data?.routeTrackings!;
    const result =
      response?.data?.routeTrackings &&
      response?.data.routeTrackings?.length > 0
        ? response?.data?.routeTrackings[0]
        : null;

    if (result != null && result.status != IStatusType.COMPLETEDTRIP) {
      routeTracking.trackerStatus = result.trackerStatus;
      //console.log("tracking service : "+result.trackerStatus)
      const locations =
        result.status === IStatusType.ONTRIP
          ? result.onTripData?.locations ?? []
          : result.offTripData?.locations;
      if (
        result.onTripData?.locations &&
        result.onTripData?.locations.length > 0
      ) {
        routeTracking.clearRouteData = result.onTripData?.clearRouteData;
      } else if (
        result.offTripData?.locations &&
        result.offTripData?.locations.length > 0
      ) {
        routeTracking.clearRouteData = result.offTripData?.clearRouteData;
      }
      const hasCoordinates =
        locations.length > 0 &&
        locations[0].lat !== undefined &&
        locations[0].lon !== undefined;
      if (hasCoordinates) {
        routeTracking.locations =
          locations?.map(
            ({lat, lon, sp, ti, di}) =>
              ({
                lat: lat,
                lng: lon,
                sp: sp,
                ti: ti,
                di: di
              }) as NeoMap
          ) ?? [];
      }
      routeTracking.returnBackKey = result.returnBackKey;
    }
    routeTracking.trackerApi = "success";
    routeTracking.referenceApi = "na";
  }
  //console.log("routeTracking", routeTracking);
  return routeTracking;
};

const routeRefDataBasedOnrouteid = async (routeId: string) => {
  const body = {
    routeId: routeId
  };
  return await axiosInstance()
    .post(`/api/route/reference`, body)
    .then((response: any) => {
      const neoresponse: NeoResponse<IReferenceRoutes> = response.data;
      return {
        userBoardings:
          neoresponse?.status === "success"
            ? neoresponse.data?.userBoardings?.map(x => ({
                lat: x?.lat,
                lng: x?.lon,
                type: x?.type,
                bpName: x?.bpName,
                users: x?.users
              })) || []
            : [],
        refRouteDetails:
          neoresponse?.status === "success"
            ? neoresponse.data?.refRouteDetails?.map(x => ({
                lat: x?.lat,
                lng: x?.lon
              })) || []
            : []
      };
    })
    .catch((error: any) => {
      return {
        refData: []
      };
    });
};
export const trackingService = {
  onGetTrackingStatus,
  onGetRouteReference,
  onGetVehicleRoutes,
  routeRefDataBasedOnrouteid
};
