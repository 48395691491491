"use client";
import {LiveFilter} from "@/components/@filter/live-filter";
import {
  NeoButton,
  NeoDatePicker,
  NeoSelect,
  NeoTextBox
} from "@/components/forms";
import {
  NeoButtonGroup,
  NeoChips,
  NeoHeader,
  NeoTabs,
  SearchIcon
} from "@/components/shared";
import {useLiveContext} from "@/context/live/live.context";
import {useNeoContext} from "@/context/neo/neo.context";
import {LiveConfig} from "@/data";
import {
  GPSTracker,
  PageType,
  PaginationAction,
  PlayBackType,
  StatusType
} from "@/libs/enums/live";
import {
  Avatar,
  Button,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SelectItem,
  Spinner,
  Tab,
  Tooltip
} from "@nextui-org/react";
import type {NextPage} from "next";
import {useRouter} from "next/navigation";
import {useEffect, useRef, useState} from "react";
import {MapView} from "./map-view";
import {TimelineView} from "./timeline-view";
import {VehicleView} from "./vehicle-view";
const liveConfig = LiveConfig as LivePageConfig;

export const Live: NextPage = function () {
  const router = useRouter();
  const leftContent = useRef<HTMLDivElement>(null);
  const rightContent = useRef<HTMLDivElement>(null);
  const [hideFeatures, setHideFeatures] = useState(true);
  const {filterContext} = useNeoContext();
  const [leftContentHeight, setLeftContentHeight] = useState(0);
  const [rightContentHeight, setRightContentHeight] = useState(0);
  const {countDown, setGpsTracker, meta, vehicleRoutes} = useLiveContext();
  const [viewTitle, setViewTitle] = useState<string>("Maps");
  const [selectedPage, setSelectedPage] = useState<PageType>(PageType.LIVE);
  const reload = useRef(false);
  const [vehicleSelctedRoute, setVehicleSelctedRoute] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<StatusType>(
    StatusType.ALL
  );
  const [selectedView, setSelectedView] = useState<PlayBackType>(
    PlayBackType.MAPS
  );
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    from: null,
    to: null
  });
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    totalPages: 10
  });
  const {filterValues, setFilterValues} = useLiveContext();

  useEffect(() => {
    if (leftContent.current)
      setLeftContentHeight(leftContent?.current?.clientHeight);
    if (rightContent.current)
      setRightContentHeight(rightContent.current.clientHeight);
  }, [selectedPage]);

  useEffect(() => {
    setVehicleSelctedRoute("");
  }, [vehicleRoutes]);

  const onDateChange = (event: any) => {
    setSelectedDateRange(event);
  };

  const handleSearch = (value: any) => {
    setFilterValues(prev => ({
      ...prev,
      searchText: value
    }));
  };

  const handleFilter = (ev: any) => {
    setFilterValues(prev => ({
      ...prev,
      driverId: ev?.driver,
      vehicleId: ev?.vehicle
    }));
  };

  useEffect(() => {
    setFilterValues(prev => ({
      ...prev,
      currentPage: pagination.currentPage,
      totalPage: pagination.totalPages
    }));
  }, [pagination]);

  useEffect(() => {
    if (meta) {
      liveConfig.statusType.map(x => {
        switch (x.key) {
          case 0:
            return (x.count = meta.totalVehicles);
          case 1:
            return (x.count = meta.inProgressTrips);
          case 2:
            return (x.count = meta.completedTrips);
          default:
            return (x.count = meta.notInTrips);
        }
      });
    }
  }, [meta]);

  const handlePagination = (action: PaginationAction) => {
    if (
      action === PaginationAction.FORWARD &&
      pagination.currentPage < pagination.totalPages
    ) {
      setPagination(prev => ({...prev, currentPage: prev.currentPage + 1}));
    } else if (
      action === PaginationAction.BACKWARD &&
      pagination.currentPage > 1
    ) {
      setPagination(prev => ({...prev, currentPage: prev.currentPage - 1}));
    }
  };

  // useEffect(() => {
  //   const handleReload = () => {
  //     if (
  //       !document.hidden &&
  //       document.visibilityState === "visible" &&
  //       !reload.current
  //     ) {
  //       console.warn("online");
  //       reload.current = true;
  //       setGpsTracker(GPSTracker.START)
  //       //window.location.reload();
  //     } else {
  //       reload.current = false;
  //       setGpsTracker(GPSTracker.STOP)
  //     }
  //   };
  //   document.addEventListener("visibilitychange", handleReload);
  //   // window.addEventListener("focus", handleReload);
  //   // return () => {
  //   //   document.removeEventListener("visibilitychange", handleReload);
  //   //   window.removeEventListener("focus", handleReload);
  //   // };
  // }, [router]);

  return (
    <div className="flex max-h-[calc(100vh-63px)] min-h-[calc(100vh-63px)] w-full">
      <div className="z-20 flex w-full">
        <div className="h-full w-28 border-r border-gray-200">
          <div
            ref={leftContent}
            className="sticky border-b border-gray-200 py-4 light:bg-neo-white"
          >
            <div className="mb-3 grid grid-cols-2">
              <div className="col-span-1 px-3">
                <NeoHeader message={liveConfig?.title}></NeoHeader>
              </div>
              <div className="col-span-1">
                <NeoButtonGroup radius="md">
                  <NeoButton
                    color={
                      selectedPage === PageType.LIVE ? "success" : "secondary"
                    }
                    size="md"
                    radius="sm"
                    onClick={() => {
                      setViewTitle("Live");
                      setSelectedPage(PageType.LIVE);
                      setSelectedStatus(StatusType.ALL);
                      setGpsTracker(GPSTracker.STOP);
                    }}
                  >
                    Live
                  </NeoButton>
                  <NeoButton
                    color={
                      selectedPage === PageType.PLAYBACK
                        ? "success"
                        : "secondary"
                    }
                    size="md"
                    radius="sm"
                    onClick={() => {
                      setViewTitle("Playback");
                      setSelectedPage(PageType.PLAYBACK);
                      setGpsTracker(GPSTracker.PLAYBACK);
                    }}
                  >
                    Playback
                  </NeoButton>
                </NeoButtonGroup>
              </div>
            </div>
            {selectedPage === PageType.LIVE && (
              <div>
                <NeoTabs
                  defaultSelectedKey={selectedStatus}
                  onSelectionChange={key => {
                    setSelectedStatus(key as StatusType);
                    setGpsTracker(GPSTracker.STOP);
                  }}
                  variant="underlined"
                  className="w-full py-3"
                >
                  {liveConfig?.statusType?.map(item => (
                    <Tab
                      key={item?.key}
                      title={
                        <div className="flex flex-col">
                          <span className="text-xs">{item?.label}</span>
                          <span className="font-bold">{item?.count}</span>
                        </div>
                      }
                    ></Tab>
                  ))}
                </NeoTabs>
              </div>
            )}
            {hideFeatures && (
              <div className="flex gap-2 p-3">
                {/* {selectedStatus == 0 && ( */}
                <FilterPopover onSubmit={handleFilter} />
                {/* )} */}
                <NeoTextBox
                  isClearable
                  className="w-full pr-2"
                  placeholder="Search"
                  value={filterValues?.searchText}
                  onChange={e => handleSearch(e?.target?.value)}
                  onClear={() => handleSearch("")}
                  startContent={<SearchIcon />}
                />
                {/* <div className="flex items-center gap-2">
                  <Button
                    onClick={() => handlePagination(PaginationAction.BACKWARD)}
                    isIconOnly
                    variant="light"
                    className="w-2 min-w-max"
                  >
                    <IoIosArrowBack />
                  </Button>
                  <Label
                    className="whitespace-nowrap text-xs"
                    value={`${pagination.currentPage} / ${pagination.totalPages}`}
                  />
                  <Button
                    onClick={() => handlePagination(PaginationAction.FORWARD)}
                    isIconOnly
                    variant="light"
                    className="w-2 min-w-max"
                  >
                    <IoIosArrowForward />
                  </Button>
                </div> */}
              </div>
            )}
          </div>
          <div
            className={`overflow-y-auto`}
            style={{height: `calc(100% - ${leftContentHeight}px)`}}
          >
            <VehicleView
              page={selectedPage}
              client={filterContext.client}
              status={selectedStatus}
            />
          </div>
        </div>
        <div className="h-full w-72">
          <div
            ref={rightContent}
            className="border-b border-gray-200 bg-neo-white py-3 dark:bg-neo-black"
          >
            <div className="grid grid-cols-5 items-end">
              <div className="col-span-2 mb-5 px-3">
                <NeoHeader message={viewTitle}></NeoHeader>
                {hideFeatures && (
                  <NeoTabs
                    defaultSelectedKey={selectedView}
                    items={liveConfig?.playBackType}
                    onSelectionChange={key => {
                      setGpsTracker(GPSTracker.STOP);
                      if (key) setSelectedView(key as PlayBackType);
                    }}
                    variant="solid"
                    className="py-3"
                  >
                    {liveConfig?.playBackType?.map(item => (
                      <Tab
                        key={item?.key}
                        title={
                          <div className="flex flex-col">
                            <span>{item?.label}</span>
                          </div>
                        }
                      ></Tab>
                    ))}
                  </NeoTabs>
                )}
              </div>
              <div
                id="PlayBackHistory"
                className="col-span-3 grid grid-cols-2 gap-2"
              >
                {selectedPage === PageType.PLAYBACK && (
                  <>
                    <div className="flex items-center justify-end">
                      <NeoDatePicker
                        range
                        showTime
                        type="icon"
                        handleDateChange={onDateChange}
                      />
                    </div>
                    {/* Routes Feature currently hide, if May be its needed, must we change the css in above div  id="PlayBackHistory"  'grid-cols-1' into 'grid-cols-2'   */}
                    <div>
                      <NeoSelect
                        placeholder="Routes"
                        labelPlacement="outside"
                        aria-label="routes"
                        classNames={{
                          trigger: "border-none bg-gray-100/50"
                        }}
                        onChange={e => setVehicleSelctedRoute(e.target.value)}
                        selectedKeys={
                          vehicleSelctedRoute ? [vehicleSelctedRoute] : []
                        }
                      >
                        {vehicleRoutes?.trips?.map((item, index) => (
                          <SelectItem key={item.id} value={item.id}>
                            {item.name}
                          </SelectItem>
                        ))}
                      </NeoSelect>
                    </div>
                  </>
                )}
                <div className="col-span-2 flex items-center justify-end">
                  <div className="flex">
                    {liveConfig?.chips?.map((item, index) => (
                      <Tooltip content={item.label} key={index} offset={-3}>
                        <NeoChips
                          key={index}
                          className="mt-1 cursor-pointer dark:text-white"
                          variant="dot"
                          color={item.value ?? undefined}
                          avatar={
                            item.image ? (
                              <Avatar
                                name="JW"
                                src="/images/boarding_change.png"
                              />
                            ) : (
                              ""
                            )
                          }
                        >
                          {item.label}
                        </NeoChips>
                      </Tooltip>
                    ))}
                  </div>
                  <div className="h-7 w-7">
                    {countDown < 6 && (
                      <Spinner
                        classNames={{
                          wrapper: "h-7 w-7"
                        }}
                        className="gap-0"
                        color="success"
                      >
                        <span className="absolute left-[37%] top-[13%] text-xs ">
                          {countDown}
                        </span>
                      </Spinner>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{height: `calc(100% - ${rightContentHeight}px)`}}>
            {selectedView == 1 ? (
              <MapView
                page={selectedPage}
                dateRange={selectedDateRange}
                tabStatus={selectedStatus}
                vehicleSelctedRoute={vehicleSelctedRoute}
              ></MapView>
            ) : (
              <TimelineView></TimelineView>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterPopover: NextPage<{onSubmit: (data: any) => void}> = ({
  onSubmit
}) => {
  const {filterValues} = useLiveContext();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const closePopOnsubmit = (data: any) => {
    onSubmit(data);
    setIsPopoverOpen(false);
  };
  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };
  const validatePopover = () => {
    const filteredNames = filterValues.vehicleId as string;
    let filteredArray: any = [];
    if (Array.isArray(filterValues?.vehicleId && filterValues?.vehicleId)) {
      filteredArray = filteredNames;
    } else if (
      filterValues?.vehicleId &&
      filterValues?.vehicleId.length > 0 &&
      filteredNames !== ""
    ) {
      filteredArray = filteredNames.split(",");
    }
    if (filteredArray.length > 0) {
      return true;
    }
    return false;
  };
  return (
    //isOpen={isPopoverOpen}
    <Popover
      placement="bottom-start"
      isOpen={isPopoverOpen}
      key={"opaque"}
      showArrow
      offset={10}
      backdrop={"opaque"}
    >
      <PopoverTrigger>
        <Button
          isIconOnly
          variant={validatePopover() ? "solid" : "light"}
          onClick={togglePopover}
          className="w-1 flex-none"
        >
          <Image
            className="rounded-none"
            alt="filter"
            src="/icons/filter.svg"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <LiveFilter onSubmit={e => closePopOnsubmit(e)} />
      </PopoverContent>
    </Popover>
  );
};
