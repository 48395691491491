"use client";
import {NeoButton, NeoSelect} from "@/components/forms";
import {useAllVehicleContext} from "@/context/live/allvehicle.context";
import {useLiveContext} from "@/context/live/live.context";
import {useNeoContext} from "@/context/neo/neo.context";
import {LiveFilterConfig} from "@/data";
import {Image, SelectItem} from "@nextui-org/react";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

/**
 * TODO: @selva,@surya,@arun
 * Create a Validation Schema if you have a form & inject them
 */
export const LiveFilter: React.FC<LiveFilterProps> = ({onSubmit}) => {
  const {register, handleSubmit, setValue} = useForm();
  const data = LiveFilterConfig;

  const {vehicles, operators} = useNeoContext();
  const {tabVehicles} = useAllVehicleContext();
  const {filterValues} = useLiveContext();
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]);
  const handleOnSubmit = (event: any) => {
    onSubmit!(event);
  };
  useEffect(() => {
    const vehicleList =
      tabVehicles?.map(x => ({
        label: x.name,
        value: x?.name
      })) || [];

    // const driverList =
    //   operators
    //     ?.map(x => {
    //       if (x.drivers.length > 0) {
    //         return x.drivers; // Flatten the drivers array
    //       }
    //       return [];
    //     })
    //     .map((x: any) => ({
    //       label: x?.name,
    //       value: x?.driverId?.toString()
    //     })) || [];
    data.vehicle = vehicleList;
    data.Driver = [];
  }, [data, tabVehicles]);
  const calculateDefaultSelectedKeys = (): string[] => {
    if (filterValues?.vehicleId && filterValues?.vehicleId !== undefined) {
      const filteredNames = filterValues.vehicleId as string;
      if (Array.isArray(filteredNames)) {
        return filteredNames;
      } else if (filterValues.vehicleId.length > 0 && filteredNames !== "") {
        return filteredNames.split(",");
      }
      return [];
    } else {
      return [];
    }
  };

  const clearvehicleFilters = () => {
    // console.log("clearvehicleFilters");
    // setFilterValues(prev => ({
    //   ...prev,
    //   vehicleId: []
    // }));
    onSubmit!({vehicle: []});
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(event => {
          handleOnSubmit(event);
        })}
      >
        <div className="flex w-[350px] flex-col gap-5 p-4">
          <NeoSelect
            {...register("vehicle")}
            label="Vehicle"
            placeholder="Select Vehicle"
            labelPlacement="outside"
            selectionMode="multiple"
            defaultSelectedKeys={calculateDefaultSelectedKeys()}
          >
            {data?.vehicle?.map(item => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </NeoSelect>

          {/* <NeoSelect
            {...register("driver")}
            label="Driver"
            placeholder="Select Driver"
            labelPlacement="outside"
            selectionMode="multiple"
          >
            {data?.Driver?.map(item => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </NeoSelect> */}
          <div className="w-100 flex justify-end gap-5">
            <NeoButton
              type="submit"
              color="yellow"
              className="gap-[5px] text-lg"
              startContent={
                <Image
                  className="h-5 w-5"
                  src="/icons/filter.svg"
                  alt="filter"
                />
              }
            >
              Filter
            </NeoButton>
            <NeoButton
              color="red"
              className="gap-[5px] text-lg"
              onClick={clearvehicleFilters}
              // startContent={
              //   <Image
              //     className="h-5 w-5"
              //     src="/icons/filter.svg"
              //     alt="filter"
              //   />
              // }
            >
              Clear
            </NeoButton>
          </div>
        </div>
      </form>
    </>
  );
};
