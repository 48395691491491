export const TripsCardConfig = [
  {
    title: "Total Trips",
    value: 250,
    color: "green"
  },
  {
    title: "Completed Trips",
    value: 50,
    color: "green",
    additionalInfo: "25 On-Time\n25 Delayed"
  },
  {
    title: "Ongoing Trips",
    value: 50,
    color: "green"
  }
];

export const AlertConfig = [
  {value: 250, label: "Harsh Driving"},
  {value: 250, label: "Violations"},
  {value: 800, label: "Over Speed"},
  {value: 250, label: "Deviation"}
];

export const ChatDefaultQuestions = [
  "Generate a bar chart with the count of harsh breaking alert for all vehicles",
  "Generate a table which displays all the institution names, vehicle name, vehicle type",
  "Generate a line chart that displays the count of distance limit alert of each vehicle",
  "Show the distance travelled by all vehicles in Kilometer",
  "generate a bar chart which displays the number of fence alerts raised for each vehicle"
];

//Currently, we have implemented only four types of charts. If we add any other types, we will definitely encounter errors in the UI.
export const SelectChartTypesList: SelectChartType[] = [
  {
    label: "Line",
    value: "line"
  },
  {
    label: "Bar",
    value: "bar"
  },
  {
    label: "Scatter",
    value: "scatter"
  },
  {
    label: "Area",
    value: "area"
  }
];
