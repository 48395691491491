import {axiosInstance} from "@/app/api";
import {formatDate, mapFields, onGetTableResponse} from "@/libs/helper";


const onGetReportData = async (url: string, body: any): Promise<any> => {
    return await axiosInstance()
      .post(url, body)
      .then((response: any) => {
        if (response?.data?.status === "success") {
          const neoresponse: NeoResponse<any> = response.data;
          var endPointData =  neoresponse?.data?.reportData;
          
          const rows = endPointData.map((row:any) => ({
            ...row,
            id: row.userId
          }));
          return {
            columns: neoresponse?.data?.columns,
            rows: rows,
            meta: neoresponse?.meta
          } as TableResponse<any>;
        }
        return onGetTableResponse();
      })
      .catch((error: any) => {
        return onGetTableResponse();
      });
  };


  export const reortsService = {
    onGetReportData
  }