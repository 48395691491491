"use client";
import {axiosInstance} from "@/app/api";
import {VehicleFormConfig} from "@/data/vehicle/form-config";
import {VehicleViewConfig} from "@/data/vehicle/view.config";
import {mapFields, onGetTableResponse} from "@/libs/helper";

const vehicleApiFileds = [
  {
    frontEndKey: "shortName",
    apikey: "shortName",
    type: "string"
  },
  {
    frontEndKey: "ownership",
    apikey: "ownerShip",
    type: "string"
  },
  {
    frontEndKey: "gpsSystem",
    apikey: "",
    type: "string"
  },
  {
    frontEndKey: "gps",
    apikey: "",
    type: "string"
  },
  {
    frontEndKey: "hardwareNo",
    apikey: "hardware",
    type: "string"
  },
  {
    frontEndKey: "fuelType",
    apikey: "fuelType",
    type: "string"
  },
  {
    frontEndKey: "vehicleType",
    apikey: "vehicleType",
    type: "string"
  },
  {
    frontEndKey: "vehicleCategory",
    apikey: "category",
    type: "string"
  },
  {
    frontEndKey: "seatingCapacity",
    apikey: "capacity",
    type: "string"
  },
  {
    frontEndKey: "operator",
    apikey: "operatorId",
    type: "string"
  },
  {
    frontEndKey: "contractStartDate",
    apikey: "contractStartDate",
    type: "date"
  },
  {
    frontEndKey: "contractEndDate",
    apikey: "contractEndDate",
    type: "date"
  },
  {
    frontEndKey: "driverName",
    apikey: "driver",
    type: "string"
  },
  {
    frontEndKey: "driverPhoneNo",
    apikey: "driverPhone",
    type: "string"
  },
  {
    frontEndKey: "maxTravelDuration",
    apikey: "maxTravelDuration",
    type: "string"
  },
  {
    frontEndKey: "maxTravelDistance",
    apikey: "maxTravelDistance",
    type: "string"
  },
  {
    frontEndKey: "startingKm",
    apikey: "",
    type: "string"
  },
  {
    frontEndKey: "vehiclePurchasedDate",
    apikey: "purchasedDate",
    type: "date"
  },
  {
    frontEndKey: "color",
    apikey: "colour",
    type: "string"
  },
  {
    frontEndKey: "releasedDate",
    apikey: "releasedDate",
    type: "date"
  }
];
const vehicleComplianceApiFileds = [
  {
    frontEndKey: "regCertificateNo",
    apikey: "rcnumber",
    type: "string"
  },
  {
    frontEndKey: "regCertificateExp",
    apikey: "rcexpiryDate",
    type: "date"
  },
  {
    frontEndKey: "pollutionCertificateNo",
    apikey: "pollutionCertificateNo",
    type: "string"
  },
  {
    frontEndKey: "pollutionCertificateExp",
    apikey: "pollutionExpiryDate",
    type: "date"
  },
  {
    frontEndKey: "firstPartyInsuranceNo",
    apikey: "firstPartyInsuranceNo",
    type: "string"
  },
  {
    frontEndKey: "firstPartyInsuranceExp",
    apikey: "firstPartyInsuranceExpiryDate",
    type: "date"
  },
  {
    frontEndKey: "thirdPartyInsuranceNo",
    apikey: "thirdPartyInsuranceNo",
    type: "string"
  },
  {
    frontEndKey: "thirdPartyInsuranceExp",
    apikey: "thirdPartyInsuranceExpiryDate",
    type: "date"
  },
  {
    frontEndKey: "permitNo",
    apikey: "permitNo",
    type: "string"
  },
  {
    frontEndKey: "permitExp",
    apikey: "permitExpiryDate",
    type: "date"
  },
  {
    frontEndKey: "fitnessExp",
    apikey: "fitnessExpiryDate",
    type: "date"
  },
  {
    frontEndKey: "roadTaxExp",
    apikey: "roadTaxExpiryDate",
    type: "date"
  },
  {
    frontEndKey: "lastServiceKM",
    apikey: "lastServiceKm",
    type: "string"
  },
  {
    frontEndKey: "lastServiceOn",
    apikey: "lastServiceDate",
    type: "date"
  },
  {
    frontEndKey: "lastTyreChangeOn",
    apikey: "lastTyreChangedDate",
    type: "date"
  }
];
const onSaveVehicleDetails = async (): Promise<any> => {
  const result = await axiosInstance().get("");
  return result;
};

const onSaveComplainceDetails = async (): Promise<any> => {
  const result = await axiosInstance().get("");
  return result;
};

const onGetVehicle = async (trackerid: string): Promise<any> => {
  const returndata = "";
  const vehicleView = VehicleViewConfig;
  const url = "api/vehicles/read?trackerId=" + trackerid;
  return await axiosInstance()
    .get(url)
    .then((response: any) => {
      if (response.data.status === "success") {
        const vehicleFields = mapFields(
          [response?.data?.data],
          vehicleView.cards[0].fields
        );
        const complainceFields = mapFields(
          [response?.data?.data],
          vehicleView.cards[1].fields
        );
        vehicleView.cards[0].fields = vehicleFields;
        vehicleView.cards[1].fields = complainceFields;
        return vehicleView;
      }
      return vehicleView;
    })
    .catch((error: any) => {
      return returndata;
    });
};

const onGetVehicles = async (url: string, body: any): Promise<any> => {
  return await axiosInstance()
    .post(url, body)
    .then((response: any) => {
      if (response?.data?.status === "success") {
        const neoresponse: NeoResponse<IVehicles> = response.data;
        const rows = neoresponse?.data?.vehicles?.map((row: any) => ({
          ...row,
          id: row.vehicleId
        }));
        return {
          columns: neoresponse?.data?.columns,
          rows: rows,
          meta: neoresponse?.meta
        } as TableResponse<Vehicle>;
      }
      return onGetTableResponse();
    })
    .catch((error: any) => {
      return onGetTableResponse();
    });
};

const onEditVehicle = async (
  url: string,
  body: any,
  additionalPrimaryData: any,
  slug: string
): Promise<any> => {
  const requestObject: any = {};
  var apiURL = "/api/vehicles/update";
  if (slug === "vehicle") {
    vehicleApiFileds.forEach(field => {
      const {frontEndKey, apikey, type} = field;

      if (Object.prototype.hasOwnProperty.call(body, frontEndKey) && apikey) {
        let formattedData = body[frontEndKey];
        if (type == "date") {
          const multiDatePickerDate = body[frontEndKey];
          if (multiDatePickerDate) {
            formattedData = `${multiDatePickerDate.getFullYear()}-${(multiDatePickerDate.getMonth() + 1).toString().padStart(2, "0")}-${multiDatePickerDate.getDate().toString().padStart(2, "0")}`;
          } else {
            formattedData = null;
          }
        }
        requestObject[apikey] = formattedData;
      }
    });
  } else {
    apiURL = "/api/vehicles/update/compliance";
    vehicleComplianceApiFileds.forEach(field => {
      const {frontEndKey, apikey, type} = field;

      if (Object.prototype.hasOwnProperty.call(body, frontEndKey) && apikey) {
        let formattedData = body[frontEndKey];
        if (type == "date") {
          const multiDatePickerDate = body[frontEndKey];
          if (multiDatePickerDate) {
            formattedData = `${multiDatePickerDate.getFullYear()}-${(multiDatePickerDate.getMonth() + 1).toString().padStart(2, "0")}-${multiDatePickerDate.getDate().toString().padStart(2, "0")}`;
          } else {
            formattedData = null;
          }
        }
        requestObject[apikey] = formattedData;
      }
    });
  }
  requestObject.trackerId = additionalPrimaryData.trackerId;
  requestObject.insId = additionalPrimaryData.insId;
  requestObject.loginUser = additionalPrimaryData.currentUser;
  return await axiosInstance().put(apiURL, requestObject);
};
const onFetchVehicleDetails = async (
  id: string,
  action: string,
  slug: string
): Promise<any> => {
  const formView = VehicleFormConfig;
  if (action === "create") {
    return {
      driverId: "",
      data: slug === "vehicle" ? formView.vehicle : formView.compliance
    };
  }
  return await axiosInstance()
    .get(`/api/vehicles/read?trackerId=${id}`)
    .then((response: any) => {
      const data = response.data.data;
      if (response.data.status === "success") {
        if (slug === "vehicle") {
          formView.vehicle.shortName = data?.shortName;
          formView.vehicle.vehicleType = data?.type;
          formView.vehicle.vehicleCategory = data?.category;
          formView.vehicle.seatingCapacity = data?.capacity;
          formView.vehicle.color = data?.colour;
          formView.vehicle.operator = data?.operator;
          formView.vehicle.driverName = data?.driver;
          formView.vehicle.fuelType = "";
          return {
            trackerId: data?.trackerId,
            data: formView.vehicle
          };
        } else {
          formView.compliance.regCertificateNo = data?.rcnumber;
          formView.compliance.regCertificateExp = convertDateStrToDateObj(
            data?.rcexpiryDate
          );
          formView.compliance.pollutionCertificateNo =
            data?.pollutionCertificateNo;
          formView.compliance.pollutionCertificateExp = convertDateStrToDateObj(
            data?.pollutionExpiryDate
          );
          formView.compliance.firstPartyInsuranceNo =
            data?.firstPartyInsuranceNo;
          formView.compliance.firstPartyInsuranceExp = convertDateStrToDateObj(
            data?.firstPartyInsuranceExpiryDate
          );
          formView.compliance.thirdPartyInsuranceNo =
            data?.thirdPartyInsuranceNo;
          formView.compliance.thirdPartyInsuranceExp = convertDateStrToDateObj(
            data?.thirdPartyInsuranceExpiryDate
          );
          formView.compliance.permitNo = data?.permitNo;
          formView.compliance.permitExp = convertDateStrToDateObj(
            data?.permitExpiryDate
          );
          formView.compliance.fitnessExp = convertDateStrToDateObj(
            data?.fitnessExpiryDate
          );
          formView.compliance.roadTaxExp = convertDateStrToDateObj(
            data?.roadTaxExpiryDate
          );
          // formView.compliance.lastServiceKM = data?.bgvComplDate;
          formView.compliance.lastServiceOn = convertDateStrToDateObj(
            data?.lastServiceDate
          );
          formView.compliance.lastTyreChangeOn = convertDateStrToDateObj(
            data?.lastTyreChangedDate
          );
          return {
            trackerId: data?.trackerId,
            data: formView.compliance
          };
        }
      } else {
        return {
          trackerId: data?.trackerId,
          data: formView.compliance
        };
      }
    })
    .catch((error: any) => {
      return {
        trackerId: id,
        data: slug === "vehicle" ? formView.vehicle : formView.compliance
      };
    });
};
const convertDateStrToDateObj = function (dateStr: string) {
  const [year, month, day] = dateStr.split("-").map(Number);
  const dateObj = new Date(year, month - 1, day); // Note: Months are zero-indexed
  return dateObj;
};
export const vehicleService = {
  onSaveVehicleDetails,
  onSaveComplainceDetails,
  onGetVehicles,
  onGetVehicle,
  onEditVehicle,
  onFetchVehicleDetails
};
