"use client";

import {NeoButton, NeoSelect, NeoTextBox} from "@/components/forms";
import {NeoError, NeoSubHeader} from "@/components/shared";
import {CountriesData} from "@/data/login/countries-data";
import {authService} from "@/service/auth.service";
import {profileService} from "@/service/profile.service";
import {yupResolver} from "@hookform/resolvers/yup";
import {SelectItem} from "@nextui-org/react";
import {ChangeEvent, useState} from "react";
import {CircleFlag} from "react-circle-flags";
import {Controller, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {AiOutlineLoading} from "react-icons/ai";
import * as yup from "yup";

const countries = CountriesData;

interface IChangeMobileForm {
  mobileNumber?: number | null;
  otp: string;
  countryCode: string;
}

interface ChangMobileProps {
  mobileNumber: number;
  onClose: Function;
}
const defaultValues: IChangeMobileForm = {
  mobileNumber: null,
  countryCode: "91",
  otp: ""
};

export const ChangeMobileForm: React.FC<ChangMobileProps> = ({
  mobileNumber,
  onClose
}) => {
  const [showOTP, setShowOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("in");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const validationSchema = yup.object().shape({
    countryCode: yup.string().required("County code is required"),
    mobileNumber: yup
      .number()
      .nullable()
      .required("Mobile Number is required")
      .typeError("Mobile number must be a number"),
    otp: yup
      .string()
      .required("OTP is required")
      .min(0, "OTP must be at least 6 numbers")
      .max(999999)
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
  });

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: {isSubmitting, isValid, errors},
    reset: resetForm
  } = useForm<any>({
    defaultValues,
    mode: "onTouched",
    resolver: yupResolver(validationSchema)
  });

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry =
      countries.find(x => x.value === e.target.value)?.flag ?? "in";
    setCountry(selectedCountry);
  };

  const onSubmit = async (data: IChangeMobileForm) => {
    setLoading(true);
    await profileService
      .changeMobileNumber(data)
      .then((response: any) => {
        if (response?.data?.status === "success") {
          toast.success("Password changed is successfully");
          onClose();
        } else {
          setErrorMessage(
            "Sorry!, We have technical issue please try again later"
          );
        }
      })
      .catch(err => {
        if (err?.errors.length > 0) {
          err?.errors.forEach(
            ({field, message}: {field: any; message: string}) => {
              setError(field, {type: "server", message: message});
            }
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      });
  };

  const handleOTP = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setLoading(true);
    await authService
      .fetchOTP({mobileNumber: mobileNumber})
      .then((response: any) => {
        if (response?.data?.status === "success") {
          toast.success("Please check your phone, your OTP is sent");
          setShowOTP(true);
        } else {
          setErrorMessage(
            "Error while sending OTP, Please check your mobile number and try again"
          );
        }
      })
      .catch(err => {
        if (err?.errors.length > 0) {
          err?.errors.forEach(
            ({field, message}: {field: any; message: string}) => {
              setError(field, {type: "server", message: message});
            }
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      });
  };

  return (
    <>
      <div className="mx-2 my-4">
        <NeoSubHeader message="Change Mobile Number"></NeoSubHeader>
      </div>
      <form
        onSubmit={handleSubmit(showOTP ? onSubmit : handleOTP)}
        className="mx-6"
      >
        {/* New Phone Number Field*/}
        <div className="py-2">
          <div className="flex items-center">
            <Controller
              name="countryCode"
              control={control}
              render={({field}) => (
                <NeoSelect
                  {...field}
                  id="countryCode"
                  labelPlacement="outside"
                  label="Country"
                  placeholder="Select"
                  onChange={handleCountryChange}
                  startContent={
                    <CircleFlag height="25" width="25" countryCode={country} />
                  }
                  className="mr-2 w-1/2"
                >
                  {countries.map(code => (
                    <SelectItem
                      key={code.value}
                      value={code.value}
                      startContent={
                        <CircleFlag
                          height="25"
                          width="25"
                          countryCode={code.flag}
                        />
                      }
                    >
                      {code.label}
                    </SelectItem>
                  ))}
                </NeoSelect>
              )}
            />
            <NeoTextBox
              label="Mobile Number"
              id="mobileNumber"
              type="text"
              placeholder="Please enter mobile number"
              color={errors.mobileNumber ? "danger" : "default"}
              isInvalid={errors.mobileNumber ? true : false}
              errorMessage={
                errors.mobileNumber && `${errors.mobileNumber.message}`
              }
              {...register("mobileNumber")}
            />
          </div>
        </div>
        {showOTP && (
          <div className="py-2">
            <NeoTextBox
              label="OTP"
              id="otp"
              size="md"
              radius="md"
              placeholder="Please enter OTP"
              color={errors.otp ? "danger" : "default"}
              isInvalid={errors.otp ? true : false}
              errorMessage={errors.otp && `${errors.otp.message}`}
              {...register("otp")}
            />
          </div>
        )}
        {errorMessage ? <NeoError message={errorMessage}></NeoError> : null}
        <div className="flex items-center justify-end p-4">
          {!showOTP ? (
            <NeoButton
              className="mx-5 mt-2"
              type="submit"
              disabled={isSubmitting}
              isLoading={loading}
              spinner={
                <AiOutlineLoading size="xs" className="h-6 w-6 animate-spin" />
              }
              color="black"
            >
              Next
            </NeoButton>
          ) : (
            <>
              <NeoButton
                className="mx-5 mt-2"
                type="submit"
                disabled={isSubmitting}
                isLoading={loading}
                spinner={
                  <AiOutlineLoading
                    size="xs"
                    className="h-6 w-6 animate-spin"
                  />
                }
                color="black"
              >
                Submit
              </NeoButton>
              <NeoButton
                className="mt-2"
                color="white"
                type="button"
                onClick={() => setShowOTP(false)}
              >
                Cancel
              </NeoButton>
            </>
          )}
        </div>
      </form>
    </>
  );
};
