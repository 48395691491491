import {NeoDescription, NeoSubHeader, WarningIcon} from "../shared";

export const ResetPasswordModal = () => {
  return (
    <div className="flex flex-col items-center gap-4 pt-4">
      <WarningIcon size={105}></WarningIcon>
      <NeoSubHeader message="Are you Sure?"></NeoSubHeader>
      <NeoDescription message="Do you really want to reset the password?"></NeoDescription>
    </div>
  );
};
