export const VehicleFormConfig = {
  institutionUserId: "",
  institutionId: "",
  trackerId: "",
  vehicle: {
    shortName: "",
    ownership: false,
    gpsSystem: false,
    gps: true,
    hardwareNo: "",
    vehicleType: "",
    fuelType: "",
    vehicleCategory: "",
    seatingCapacity: 0,
    operator: "",
    contractStartDate: "",
    contractEndDate: "",
    driverName: "",
    driverPhoneNo: "",
    maxTravelDuration: "",
    maxTravelDistance: "",
    startingKm: "",
    vehiclePurchasedDate: "",
    color: "",
    releasedDate: ""
  },
  compliance: {
    regCertificateNo: "",
    regCertificateExp: new Date(),
    pollutionCertificateNo: "",
    pollutionCertificateExp: new Date(),
    firstPartyInsuranceNo: "",
    firstPartyInsuranceExp: new Date(),
    thirdPartyInsuranceNo: "",
    thirdPartyInsuranceExp: new Date(),
    permitNo: "",
    permitExp: new Date(),
    fitnessExp: new Date(),
    roadTaxExp: new Date(),
    lastServiceKM: "",
    lastServiceOn: new Date(),
    lastTyreChangeOn: new Date()
  }
};
