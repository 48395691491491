"use client";
import {NeoButton, NeoTextBox} from "@/components/forms";
import {NeoDescription, NeoError, NeoHeader} from "@/components/shared";
import {decrypt} from "@/libs/helper/util";
import {authService} from "@/service/auth.service";
import {yupResolver} from "@hookform/resolvers/yup";
import Link from "next/link";
import {useRouter} from "next/navigation";
import {parseCookies} from "nookies";
import {useState} from "react";
import {useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {AiOutlineLoading} from "react-icons/ai";
import * as yup from "yup";

interface IResetPassword {
  newpassword: string;
  confirmpassword: string;
  otp: string;
}
interface IChangePassword {
  mobileNumber?: any;
  userName?: any;
  resetType?: string;
}

interface IResetPasswordForm {
  mobileNumber?: any;
  userName?: any;
  otp: string;
  password: string;
}

const defaultValues: IResetPassword = {
  newpassword: "",
  confirmpassword: "",
  otp: ""
};
const sKey: string = process.env.NEXT_PUBLIC_NEO_SECRET || "";
export const ResetPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const cookies = parseCookies(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const router = useRouter();
  const [reset, setResetState] = useState<IChangePassword>({
    resetType: cookies["neo.reset-type"],
    userName: cookies["neo.u-name"],
    mobileNumber: cookies["neo.contact-no"]
  });

  const validationSchema = yup.object().shape({
    newpassword: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmpassword: yup
      .string()
      .required("Confirm Password is required")
      .min(8, "Password must be at least 8 characters")
      .oneOf([yup.ref("newpassword"), ""], "Passwords must match"),
    otp: yup
      .string()
      .required("OTP is required")
      .min(0, "OTP must be at least 4 numbers")
      .max(9999)
      .transform((value, originalValue) =>
        String(originalValue).trim() === "" ? null : value
      )
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: {isSubmitting, errors}
  } = useForm<IResetPassword>({
    defaultValues,
    mode: "onTouched",
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: IResetPassword) => {
    setLoading(true);
    const body: IResetPasswordForm = {
      otp: data.otp,
      password: data.confirmpassword
    };
    if (reset.resetType == "neo.contact-no" && reset.mobileNumber) {
      body.mobileNumber = await decrypt(reset.mobileNumber, sKey);
    } else {
      body.userName = await decrypt(reset.userName, sKey);
    }
    await authService
      .resetPassword(body)
      .then((response: any) => {
        if (response?.data?.status === "success") {
          router.push("/auth/login");
        } else {
          setErrorMessage(
            "Sorry! Something went wrong. Please try again later"
          );
        }
      })
      .catch(err => {
        if (err?.errors.length > 0) {
          err?.errors.forEach(
            ({field, message}: {field: any; message: string}) => {
              setError(field, {type: "server", message: message});
            }
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      });
  };

  const handleOTP = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setLoading(true);
    let body = {};
    if (reset.resetType == "neo.contact-no" && reset.mobileNumber) {
      const mobileNumber = await decrypt(reset.mobileNumber, sKey);
      body = {mobileNumber: mobileNumber};
    } else {
      const userName = await decrypt(reset.userName, sKey);
      body = {userName: userName};
    }
    await authService
      .fetchOTP(body)
      .then((response: any) => {
        if (response?.data?.status === "success") {
          toast.success("Please check your phone, your OTP is sent");
        } else {
          setErrorMessage(
            "Error while sending OTP, Please check your mobile number and try again"
          );
        }
      })
      .catch(err => {
        if (err?.errors.length > 0) {
          err?.errors.forEach(
            ({field, message}: {field: any; message: string}) => {
              setError(field, {type: "server", message: message});
            }
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mx-10 w-full px-5">
      <div className="my-5">
        <NeoHeader message="Reset Password"></NeoHeader>
      </div>

      {/* New Password Field*/}
      <div className="py-2">
        <NeoTextBox
          label="New Password"
          id="newpassword"
          type="password"
          placeholder="Please enter new password"
          color={errors.newpassword ? "danger" : "default"}
          isInvalid={errors.newpassword ? true : false}
          errorMessage={errors.newpassword && `${errors.newpassword.message}`}
          {...register("newpassword")}
        />
      </div>

      {/* Confirm Password Field*/}
      <div className="py-2">
        <NeoTextBox
          label="Confirm Password"
          id="confirmpassword"
          type="password"
          placeholder="Please enter confirm password"
          color={errors.confirmpassword ? "danger" : "default"}
          isInvalid={errors.confirmpassword ? true : false}
          errorMessage={
            errors.confirmpassword && `${errors.confirmpassword.message}`
          }
          {...register("confirmpassword")}
        />
      </div>

      <div className="py-2">
        <NeoTextBox
          label="OTP"
          id="otp"
          placeholder="Please enter OTP"
          color={errors.otp ? "danger" : "default"}
          isInvalid={errors.otp ? true : false}
          errorMessage={errors.otp && `${errors.otp.message}`}
          {...register("otp")}
        />
      </div>

      {/* Resend OTP ACTION */}
      <div className="mt-2 block pb-2">
        <div className="flex items-center justify-between">
          <div className="flex justify-between">
            <NeoDescription message="Did not receive OTP ?" />
            <Link href="#" legacyBehavior>
              <a className="px-2 text-sm text-neo-green" onClick={handleOTP}>
                Resend
              </a>
            </Link>
          </div>
        </div>
      </div>
      {errorMessage ? <NeoError message={errorMessage}></NeoError> : null}
      <NeoButton
        className="mt-2 w-full"
        type="submit"
        disabled={isSubmitting}
        isLoading={loading}
        size="xl"
        spinner={
          <AiOutlineLoading size="xs" className="h-6 w-6 animate-spin" />
        }
        color="black"
      >
        Reset
      </NeoButton>
    </form>
  );
};
