import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  extendVariants,
  getKeyValue
} from "@nextui-org/react";
import {useMemo, useState} from "react";

export default function SharedTable({headers, data}: SharedTableProps) {
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  const pages = Math.ceil(data.length / rowsPerPage);

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return data.slice(start, end);
  }, [page, data]);

  return (
    <div>
      <MasterTable
        isHeaderSticky
        aria-label="Example table with dynamic content"
        classNames={{
          base: "overflow-auto p-2",
          wrapper: "p-2 min-h-[250px] max-h-[44vh] relative"
        }}
      >
        <TableHeader columns={headers}>
          {column => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody emptyContent={"No record to display."}>
          {items.map((item, index) => (
            <TableRow key={index}>
              {columnKey => (
                <TableCell>{getKeyValue(item, columnKey)}</TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </MasterTable>
      <div className="flex items-center justify-between p-1 px-2">
        <Pagination
          isCompact
          showControls
          showShadow
          page={page}
          total={pages}
          onChange={page => setPage(page)}
        />
      </div>
    </div>
  );
}

//Custome Table configaration
export const MasterTable = extendVariants(Table, {
  variants: {
    radius: {
      none: {
        wrapper: "rounded-none"
      },
      sm: {
        wrapper: "rounded-[8px]"
      },
      md: {
        wrapper: "rounded-[8px]"
      },
      lg: {
        wrapper: "rounded-[8px]"
      }
    },
    shadow: {
      none: {
        wrapper: "shadow-none"
      },
      sm: {
        wrapper: "shadow-small"
      },
      md: {
        wrapper: "shadow-small"
      },
      lg: {
        wrapper: "shadow-small"
      }
    },
    isHeaderSticky: {
      true: {
        thead:
          "sticky top-0 z-20 [&>tr]:first:shadow-none [&>tr]:first:border-solid [&>tr]:first:border-b-2 [&>tr]:first:border-default-200"
      }
    }
  },
  defaultVariants: {
    layout: "auto",
    shadow: "sm",
    radius: "lg",
    color: "default"
  }
});
