"use client";
import {
  NeoBreadcrumb,
  NeoHeader,
  NeoLabel,
  NeoSubHeader
} from "@/components/shared";
import {decryptUrl} from "@/libs/helper";
import {userService} from "@/service/users.service";
import {Card, CardBody, CardHeader, Spinner} from "@nextui-org/react";
import {useEffect, useState} from "react";
const sKey: string = process.env.NEXT_PUBLIC_NEO_SECRET || "";

interface ManageUserProps {
  params: PageProps;
}
interface PageProps {
  id: number | string;
  action: string;
  slug: string;
}
export const ViewUser: React.FC<ManageUserProps> = function ({params}) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const id = await decryptUrl(params.id as string, sKey);
      setLoading(true);
      await userService
        .onGetUser(id as string)
        .then((response: any) => {
          setData(response);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, [params]);

  return (
    <>
      {loading ? (
        <Spinner color="default" />
      ) : (
        <div className="flex min-h-[calc(100vh-63px)] w-full flex-col p-5">
          <div className="mb-5 flex-row">
            <NeoHeader message="Users"></NeoHeader>
          </div>
          <div className="mb-5 flex-row">
            <NeoBreadcrumb menus={breadCrumb}></NeoBreadcrumb>
          </div>
          <div className="w-full flex-row">
            {data.cards.map((item: any, key: any) => (
              <Card key={key} className="mb-5">
                <CardHeader>
                  <div className="px-3 py-2">
                    <NeoSubHeader message={item.title}></NeoSubHeader>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="flex-row px-3">
                    <div className="flex flex-wrap">
                      {item.fields.map((field: TNeoField, key: any) => {
                        if (field?.isVisible) {
                          return (
                            <div className="mb-5 w-1/4" key={key}>
                              <NeoLabel
                                label={field.label}
                                value={field.value}
                                type={field.type}
                              ></NeoLabel>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
const breadCrumb = [
  {
    link: "/users",
    label: "Users"
  },
  {
    link: "",
    label: "View User Details"
  }
];
