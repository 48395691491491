export const RFIDdetails = [
  {
    label: "Parent Name",
    value: "NA"
  },
  {
    label: "Student Name",
    value: "NA"
  },
  {
    label: "Phone Number",
    value: "NA"
  },
  {
    label: "Class",
    value: "NA"
  },
  {
    label: "Old RFID No",
    value: "NA"
  }
];
