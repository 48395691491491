"use client";

import {NeoButton, NeoSelect, NeoTextBox} from "@/components/forms";
import {NeoError, NeoHeader} from "@/components/shared";
import {yupResolver} from "@hookform/resolvers/yup";
import {SelectItem} from "@nextui-org/react";
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";

const countries = [
  {label: "Pickup", value: "pickup"},
  {label: "Drop", value: "dropped"},
  {label: "Phone Number", value: "phoneno"}
];
interface SmartEditProps {
  onClose: any;
  onSubmitPayload?: (payload: any) => void | undefined;
  selectedRow: any;
}

export const SmartEdit: React.FC<SmartEditProps> = ({
  onClose,
  onSubmitPayload = () => {},
  selectedRow
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const validationSchema = yup.object().shape({
    fields: yup.string().required("Please select a field"),
    phoneNumber: yup.number().when("fields", {
      is: (fields: any) => fields && fields.includes("phoneno"),
      then: () => yup.number().required()
    }),
    pickUp: yup.string().when("fields", {
      is: (fields: any) => fields && fields.includes("pickup"),
      then: () =>
        yup
          .string()
          .required()
          .test(
            "maxSelection",
            "You cannot select more then 2 vehicles",
            value => {
              if (value !== undefined) {
                const selectedValues = value.split(",").map(v => v.trim());
                return selectedValues.length <= 2;
              }
              return false;
            }
          )
    }),
    drop: yup.string().when("fields", {
      is: (fields: any) => fields && fields.includes("dropped"),
      then: () =>
        yup
          .string()
          .required()
          .test(
            "maxSelection",
            "You cannot select more then 2 vehicles",
            value => {
              if (value !== undefined) {
                const selectedValues = value.split(",").map(v => v.trim());
                return selectedValues.length <= 2;
              }
              return false;
            }
          )
    })
  });

  const {
    control,
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    watch
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(validationSchema)
  });
  const selectedFields = watch("fields");
  const onSubmitSmartEdit = () => {};
  return (
    <>
      <div className="my-5 px-4">
        <NeoHeader message="Smart Edit"></NeoHeader>
      </div>
      <form onSubmit={handleSubmit(onSubmitSmartEdit)} className="w-full px-3">
        <div className="py-2">
          <div className="flex items-center">
            <Controller
              name="fields"
              control={control}
              render={({field}) => (
                <NeoSelect
                  {...field}
                  id="fields"
                  labelPlacement="outside"
                  label="Field"
                  placeholder="Select"
                  className="mr-2"
                  selectionMode="multiple"
                  //onSelectionChange={handleSelectionChange}
                  color={errors.fields ? "danger" : "default"}
                  isInvalid={errors.fields ? true : false}
                  errorMessage={errors.fields && `${errors.fields.message}`}
                >
                  {countries.map(code => (
                    <SelectItem key={code.value} value={code.value}>
                      {code.label}
                    </SelectItem>
                  ))}
                </NeoSelect>
              )}
            />
          </div>
          {/* Conditionally render Textbox based on the selected fields */}
          {selectedFields && selectedFields.includes("phoneno") && (
            <div className="py-2">
              <NeoTextBox
                label="Phone Number"
                id="phoneNumber"
                placeholder="Please enter phone number"
                color={errors.phoneNumber ? "danger" : "default"}
                isInvalid={errors.phoneNumber ? true : false}
                errorMessage={
                  errors.phoneNumber && `${errors.phoneNumber.message}`
                }
                {...register("phoneNumber")}
              />
            </div>
          )}
          {selectedFields && selectedFields.includes("pickup") && (
            <div className="py-2">
              <div className="flex items-center">
                <Controller
                  name="pickUp"
                  control={control}
                  render={({field}) => (
                    <NeoSelect
                      {...field}
                      id="pickUp"
                      labelPlacement="outside"
                      label="Pickup Vehicle"
                      placeholder="Select"
                      className="mr-2"
                      selectionMode="multiple"
                      color={errors.pickUp ? "danger" : "default"}
                      isInvalid={errors.pickUp ? true : false}
                      errorMessage={errors.pickUp && `${errors.pickUp.message}`}
                    >
                      {countries.map(code => (
                        <SelectItem key={code.value} value={code.value}>
                          {code.label}
                        </SelectItem>
                      ))}
                    </NeoSelect>
                  )}
                />
              </div>
            </div>
          )}
          {selectedFields && selectedFields.includes("dropped") && (
            <div className="py-2">
              <div className="flex items-center">
                <Controller
                  name="drop"
                  control={control}
                  render={({field}) => (
                    <NeoSelect
                      {...field}
                      id="drop"
                      labelPlacement="outside"
                      label="Drop Vehicle"
                      placeholder="Select"
                      className="mr-2"
                      selectionMode="multiple"
                      color={errors.drop ? "danger" : "default"}
                      isInvalid={errors.drop ? true : false}
                      errorMessage={errors.drop && `${errors.drop.message}`}
                    >
                      {countries.map(code => (
                        <SelectItem key={code.value} value={code.value}>
                          {code.label}
                        </SelectItem>
                      ))}
                    </NeoSelect>
                  )}
                />
              </div>
            </div>
          )}
        </div>
        {errorMessage ? <NeoError message={errorMessage}></NeoError> : null}
        <div className="mt-2 flex items-center justify-end p-4">
          <NeoButton color="white" onPress={onClose}>
            Close
          </NeoButton>
          <NeoButton className="mx-5" type="submit" color="black">
            Save
          </NeoButton>
        </div>
      </form>
    </>
  );
};
