import {ActiveStatus} from "@/libs/enums/live";
import React from "react";
import {
  NeoAction,
  NeoName,
  NeoOption,
  NeoPickupDrop,
  NeoStatus,
  NeoValue
} from "../neo-fields";

export interface NeoCellProps {
  row: any;
  columnKey: string;
  cellValue: any;
  actions: any;
  type: any;
  handleAction: (key: string, userId: any, row: any) => void;
  meta: any;
}
export const NeoCells: React.FC<NeoCellProps> = ({
  row,
  columnKey,
  cellValue,
  actions,
  handleAction,
  type,
  meta
}) => {
  switch (type) {
    case "name":
      return <NeoName data={row} cellValue={cellValue}></NeoName>;
    case "value":
      return <NeoValue data={row} cellValue={cellValue}></NeoValue>;
    case "status":
      return (
        <NeoStatus
          status={cellValue == 0 ? ActiveStatus.INACTIVE : ActiveStatus.ACTIVE}
        ></NeoStatus>
      );
    case "pickupdrop":
      return (
        <NeoPickupDrop
          data={row?.pickup ? row?.pickup : row?.drop}
        ></NeoPickupDrop>
      );
    case "option":
      return <NeoOption data={row} cellValue={cellValue}></NeoOption>;
    case "actions":
      return (
        <NeoAction
          data={row}
          actions={actions}
          meta={meta}
          handleAction={handleAction}
        ></NeoAction>
      );

    default:
      return cellValue;
  }
};
