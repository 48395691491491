"use client";

import {Image} from "@nextui-org/react";
import type {NextPage} from "next";
import {Cover} from "../cover/cover";
import {ForgotPasswordForm} from "./forgot-password.form";

export const ForgotPassword: NextPage = function () {
  return (
    <div className="flex h-full w-full">
      <Cover />
      <div className="relative flex h-screen w-1/2 flex-col items-center justify-center bg-white">
        <div className="flex w-full  flex-col justify-center">
          <div className="flex justify-center">
            <Image
              src="/images/logo.svg"
              alt="Neo Track"
              width={325}
              height={150}
            />
          </div>
          <div className="flex w-full p-2">
            <ForgotPasswordForm />
          </div>
        </div>
      </div>
    </div>
  );
};
