"use client";
import {NeoButton, NeoSelect} from "@/components/forms";
import {NeoSwitchInput} from "@/components/shared/neo-switch/neo-switch-input";
import {useNeoContext} from "@/context/neo/neo.context";
import {Image, SelectItem} from "@nextui-org/react";
import {useForm} from "react-hook-form";

/**
 * TODO: @selva,@surya,@arun
 * Create a Validation Schema if you have a form & inject them
 */
export const DriversFilter: React.FC<DriverFilterProps> = ({onSubmit}) => {
  const {register, handleSubmit, setValue} = useForm();
  const {categories, operators} = useNeoContext();

  // Handle switch input change and update form value
  const handleSwitchChange = (name: string, value: boolean) => {
    setValue(name, value);
  };

  const onSubmitEvent = (event: any) => {
    onSubmit(event);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(event => {
          onSubmitEvent(event);
        })}
      >
        <div className="flex w-[350px] flex-col gap-5 p-2">
          <NeoSelect {...register("operator")} label="Operator">
            {operators?.map(item => (
              <SelectItem key={item.operatorId} value={item.operatorId}>
                {item.operatorName}
              </SelectItem>
            ))}
          </NeoSelect>
          <NeoSwitchInput
            label="Status"
            activeLabel="Active"
            inActiveLabel="Inactive"
            onValueChange={value => handleSwitchChange("status", value)}
          />
          {/* <NeoSwitchInput
            label="RFID"
            activeLabel="Enabled"
            inActiveLabel="Disabled"
            onValueChange={value => handleSwitchChange("rfid", value)}
          />
          <NeoSwitchInput
            label="BGV Status"
            activeLabel="Completed"
            inActiveLabel="Not Completed"
            onValueChange={value => handleSwitchChange("bgvStatus", value)}
          />
          <NeoSwitchInput
            label="Induction Status"
            activeLabel="Completed"
            inActiveLabel="Not Completed"
            onValueChange={value => handleSwitchChange("employmentType", value)}
          /> */}
          <div className="w-100 flex justify-end">
            <NeoButton
              type="submit"
              color="yellow"
              className="gap-[5px] text-lg"
              startContent={
                <Image
                  className="h-5 w-5"
                  src="/icons/filter.svg"
                  alt="filter"
                />
              }
            >
              Filter
            </NeoButton>
          </div>
        </div>
      </form>
    </>
  );
};
