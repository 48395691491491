"use client";
import {Image} from "@nextui-org/react";
import {NextPage} from "next";
import {useEffect, useState} from "react";

export const Notification: NextPage = () => {
  const [dataSource, setDataSource] = useState(NotificationData);
  const [isShowList, setIsShowList] = useState<boolean>(false);

  useEffect(() => {
    setIsShowList(dataSource && dataSource.length > 0);
  }, [dataSource]);

  const onClearAll = (): void => {
    setDataSource([]);
  };

  const onGetNotification = (): void => {
    //Get Api
  };

  return (
    <>
      <section>
        <div className="flex flex-col">
          {isShowList ? (
            dataSource.map((x: any, i: any) => (
              <div key={i} className="flex flex-col border-b px-4 pt-2">
                <div className="flex justify-end text-sm text-gray-400">
                  <span>{x?.updatedDateTime}</span>
                </div>
                <div className="flex w-full items-center gap-2 py-2">
                  {x?.isNotRead ? (
                    <span className="h-2 w-2 rounded-full bg-neo-green"></span>
                  ) : (
                    <></>
                  )}
                  <Image
                    className="min-h-7 min-w-7 rounded-none"
                    src={
                      NotificationType[x?.type as keyof typeof NotificationType]
                    }
                    alt="filter"
                  />
                  <span className="w-10/12 text-base">{x?.message}</span>
                </div>
              </div>
            ))
          ) : (
            <div className="mt-10 grid justify-center gap-3 p-3">
              <Image
                className="h-full w-full"
                src="/images/noNotifications.svg"
                alt="filter"
              />
              <h1 className="text-xl font-semibold">No Notifications Found</h1>
            </div>
          )}
          {isShowList && (
            <div className="flex w-full justify-end p-4">
              <label
                onClick={onClearAll}
                className="text-lg font-bold text-neo-green underline underline-offset-2"
              >
                Clear all
              </label>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export const NotificationData = [
  {
    updatedDateTime: "10-11-2023 | 11:56 PM",
    isNotRead: true,
    message:
      "Alert !! Vehicle AK8355 is idle for 15 minutes on 09-11-2023 11:56 PM",
    type: "alert"
  },
  {
    updatedDateTime: "10-11-2023 | 11:56 PM",
    isNotRead: false,
    message:
      "Warning !! Vehicle AK8355 is idle for 15 minutes on 09-11-2023 11:56 PM",
    type: "warn"
  }
];

enum NotificationType {
  alert = "/icons/alert.svg",
  warn = "/icons/warning.svg"
}
