"use client";

import {NeoButton, NeoSelect, NeoTextBox} from "@/components/forms";
import {NeoDescription, NeoError, NeoHeader} from "@/components/shared";
import {CountriesData} from "@/data/login/countries-data";
import {encrypt} from "@/libs/helper/util";
import {authService} from "@/service/auth.service";
import {yupResolver} from "@hookform/resolvers/yup";
import {SelectItem} from "@nextui-org/react";
import Link from "next/link";
import {useRouter} from "next/navigation";
import {setCookie} from "nookies";
import {ChangeEvent, useState} from "react";
import {CircleFlag} from "react-circle-flags";
import {Controller, useForm} from "react-hook-form";
import {AiOutlineLoading} from "react-icons/ai";
import * as yup from "yup";

interface IForgotPassword {
  countryCode?: string;
  userName?: string;
  mobileNumber?: number | null;
}

const defaultValues: IForgotPassword = {
  countryCode: "91",
  userName: "",
  mobileNumber: null
};
const countries = CountriesData;

const sKey: string = process.env.NEXT_PUBLIC_NEO_SECRET || "";
export const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [activateMobile, setActivateMobile] = useState(true);
  const [country, setCountry] = useState("in");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const router = useRouter();
  const validationSchema = (activateMobile: boolean) =>
    yup.object().shape({
      countryCode:
        activateMobile != true
          ? yup.string()
          : yup.string().required("County code is required"),
      mobileNumber:
        activateMobile != true
          ? yup.number().nullable()
          : yup
              .number()
              .nullable()
              .required("Mobile Number is required")
              .typeError("Mobile number must be a number"),
      userName:
        activateMobile == true
          ? yup.string()
          : yup.string().required("Username is required")
    });

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry =
      countries.find(x => x.value === e.target.value)?.flag ?? "in";
    setCountry(selectedCountry);
  };

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: {isSubmitting, errors}
  } = useForm<IForgotPassword>({
    defaultValues,
    mode: "onTouched",
    resolver: yupResolver(validationSchema(activateMobile))
  });
  const onSetCookies = async (mobileNumber?: string, userName?: string) => {
    if (activateMobile) {
      const contactNo = await encrypt(mobileNumber, sKey);
      setCookie(null, "neo.reset-type", "neo.contact-no", {
        maxAge: 30 * 24 * 60 * 60,
        path: "/"
      });
      if (contactNo) {
        setCookie(null, "neo.contact-no", contactNo, {
          maxAge: 30 * 24 * 60 * 60,
          path: "/"
        });
      }
    } else {
      const uName = await encrypt(userName, sKey);
      setCookie(null, "neo.reset-type", "neo.u-name", {
        maxAge: 30 * 24 * 60 * 60,
        path: "/"
      });
      if (uName) {
        setCookie(null, "neo.u-name", uName, {
          maxAge: 30 * 24 * 60 * 60,
          path: "/"
        });
      }
    }
  };

  const onSubmit = async (data: IForgotPassword) => {
    setLoading(true);
    const body =
      activateMobile == true
        ? {
            mobileNumber: !isNaN(Number(data.mobileNumber))
              ? data.countryCode?.concat(String(data.mobileNumber))
              : undefined
          }
        : {userName: data.userName};

    await authService
      .fetchOTP(body)
      .then((response: any) => {
        if (response?.data?.status === "success") {
          onSetCookies(body.mobileNumber, body.userName);
          router.push("/auth/reset-password");
        } else {
          setErrorMessage(
            "Error while sending OTP, Please check your mobile number and try again"
          );
        }
      })
      .catch(err => {
        if (err?.errors.length > 0) {
          err?.errors.forEach(
            ({field, message}: {field: any; message: string}) => {
              setError(field, {type: "server", message: message});
            }
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mx-10 w-full px-5">
      <div className="my-5">
        <NeoHeader message="Forgot Password"></NeoHeader>
      </div>
      <div className="my-5">
        <NeoDescription message="Enter your username/phone for the verification process, we will send 6 digits code to your registered phone"></NeoDescription>
      </div>

      {activateMobile ? (
        <div>
          {/* Phone Field*/}
          <div className="py-2">
            <div className="flex items-center">
              <Controller
                name="countryCode"
                control={control}
                render={({field}) => (
                  <NeoSelect
                    {...field}
                    id="countryCode"
                    labelPlacement="outside"
                    label="Country"
                    placeholder="Select"
                    onChange={handleCountryChange}
                    startContent={
                      <CircleFlag
                        height="25"
                        width="25"
                        countryCode={country}
                      />
                    }
                    className="mr-2 w-1/6"
                  >
                    {countries.map(code => (
                      <SelectItem
                        key={code.value}
                        value={code.value}
                        startContent={
                          <CircleFlag
                            height="25"
                            width="25"
                            countryCode={code.flag}
                          />
                        }
                      >
                        {code.label}
                      </SelectItem>
                    ))}
                  </NeoSelect>
                )}
              />
              <NeoTextBox
                {...register("mobileNumber")}
                id="mobileNumber"
                labelPlacement="outside"
                className="w-5/6"
                label="Mobile Number"
                color={errors.mobileNumber ? "danger" : "default"}
                isInvalid={errors.mobileNumber ? true : false}
                errorMessage={
                  errors.mobileNumber && `${errors.mobileNumber.message}`
                }
                placeholder="Please enter mobile number"
              />
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Link
              className="text-neo-green"
              href=""
              onClick={e => setActivateMobile(false)}
            >
              Reset using username
            </Link>
          </div>
        </div>
      ) : (
        <div>
          {/* Username Field*/}
          <div className="py-2">
            <NeoTextBox
              label="Username"
              id="userName"
              placeholder="Please enter username"
              color={errors.userName ? "danger" : "default"}
              isInvalid={errors.userName ? true : false}
              errorMessage={errors.userName && `${errors.userName.message}`}
              {...register("userName")}
            />
          </div>
          <div className="flex items-center justify-end">
            <Link
              className="text-neo-green"
              href=""
              onClick={e => setActivateMobile(true)}
            >
              Reset using phone
            </Link>
          </div>
        </div>
      )}
      {errorMessage ? <NeoError message={errorMessage}></NeoError> : null}
      <NeoButton
        className="mt-5 w-full"
        type="submit"
        disabled={isSubmitting}
        isLoading={loading}
        size="xl"
        spinner={
          <AiOutlineLoading size="xs" className="h-6 w-6 animate-spin" />
        }
        color="black"
      >
        Continue
      </NeoButton>
    </form>
  );
};
