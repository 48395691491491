export const VehicleFilterConfig = {
  vehicleType: [
    {
      label: "Bus",
      value: "1"
    },
    {
      label: "Mini Bus",
      value: "2"
    },
    {
      label: "Van",
      value: "3"
    },
    {
      label: "Mini Van",
      value: "4"
    },
    {
      label: "Car",
      value: "5"
    },
    {
      label: "Jeep",
      value: "6"
    }
  ],
  fuleType: [
    {
      label: "Diesel",
      value: "1"
    },
    {
      label: "Petrol",
      value: "2"
    },
    {
      label: "EV",
      value: "3"
    }
  ],
  category: [
    {
      label: "House Drop",
      value: "1"
    },
    {
      label: "Office",
      value: "2"
    },
    {
      label: "Common",
      value: "3"
    }
  ]
};
