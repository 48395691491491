"use client";
import {NeoHeader} from "@/components/shared";
import {NeoTable} from "@/components/table";
import {DriversTableConfig} from "@/data/drivers/table.config";
import {NextPage} from "next/types";

export const Drivers: NextPage = function () {
  const data = DriversTableConfig;
  return (
    <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
      <div className="mb-4 flex-row px-3">
        <NeoHeader message="Drivers"></NeoHeader>
      </div>
      <div className="flex-row">
        <NeoTable table={data.table}></NeoTable>
      </div>
    </div>
  );
};
