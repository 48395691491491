"use client";
import {NeoHeader} from "@/components/shared";
import type {NextPage} from "next";

export const Configuration: NextPage = function () {
  return (
    <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
      <div className="mb-4 flex-row px-3">
        <NeoHeader message="Configuration"></NeoHeader>
      </div>
      <div className="flex-row"></div>
    </div>
  );
};
