"use client";

import {NeoModal} from "@/components/@modal/neo.modal";
import {useNeoContext} from "@/context/neo/neo.context";
import {encryptUrl} from "@/libs/helper/util";
import {tableService} from "@/service/table.service";
import {
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  extendVariants
} from "@nextui-org/react";
import {useRouter} from "next/navigation";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {NeoCells, NeoTableFooter, NeoTableHeader} from "..";
const sKey: string = process.env.NEXT_PUBLIC_NEO_SECRET || "";

const statusOptions = [
  {name: "Active", uid: "active"},
  {name: "InActive", uid: "inactive"}
];
type SortDesc = {
  column: string | number;
  direction: any;
};
export const NeoTable: React.FC<NeoTableProps> = ({table}) => {
  const {filterContext} = useNeoContext();
  const {columns, activeColumns,activeFilters, meta, style, actions, url} = table;
  const {push} = useRouter();
  const [modalOpen, setModalOpen] = useState(false);
  const [reportFilters,setReportFilters] = useState({});
  const [actionType, setActionType] = useState<any | null>(null);
  const [filterValue, setFilterValue] = useState<string | "">("");
  const [selectedKeys, setSelectedKeys] = useState<any | null>();
  const [visibleColumns, setVisibleColumns] = useState<any>(
    new Set(activeColumns)
  );
  const [rowsData, setRowData] = useState();
  const [statusFilter, setStatusFilter] = useState("all");
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [sortDescriptor, setSortDescriptor] = useState<any | null>({});

  const [filters, setFilters] = useState({
    filterColumn: [],
    filterValues: []
  });
  const [page, setPage] = useState(1);
  const [data, setData] = useState<TableResponse<any>>();
  const hasSearchFilter = Boolean(filterValue);

  useEffect(() => { 
    setVisibleColumns(activeColumns);
  },[activeColumns]);

  const headerColumns = useMemo(() => {
    if (visibleColumns === "all") return columns;
    return columns.filter((column: any) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns, columns]);


  // search items
  const [apiUsers, setApiUsers] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const filteredItems = useMemo(() => {
    let filteredRecords = [...(hasSearchFilter ? apiUsers : ([] as any))];

    if (hasSearchFilter) {
      filteredRecords = filteredRecords.filter(user =>
        user.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== statusOptions.length
    ) {
      filteredRecords = filteredRecords.filter(data =>
        Array.from(statusFilter).includes(data.status)
      );
    }

    return filteredRecords;
  }, [hasSearchFilter, apiUsers, statusFilter, filterValue]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const onRowsPerPageChange = useCallback((e: any) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = useCallback((value: any) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const handleAction = useCallback(
    (key: any, id: any, row: any) => {
      const item = actions?.find(x => x.key == key);
      if (item) {
        if (item.type === "navigate") {
          const route = (url.page as any)[key];
          const navigate = async () => {
            const navId = await encryptUrl(row.id, sKey);
            const routeWithId = route
              ? route.replace("${navId}", navId)
              : route;
            push(routeWithId);
          };
          navigate();
        } else {
          setActionType(item);
          setRowData(row);
          onModalOpen();
        }
      } else {
        console.error(
          "Invalid table configuration, Please check the relevant table configuration"
        );
      }
    },
    [actions, push, url]
  );

  const handleRowAction = useCallback(
    (key: React.Key) => {
      const id = items.find((_, index) => index === Number(key))?.id ?? null;
      const navigate = async () => {
        const navId = await encryptUrl(id as string, sKey);
        const route = url?.page?.view;
        if (route && navId) push(route.replace("${navId}", navId));
      };
      navigate();
    },
    [items, push, url]
  );

  const onModalOpen = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  /**
   *  WARNING:
   *  This method should only be used for modal dialog without any text box
   *  and it can handle the mentioned actions
   *  1. delete
   *  2. deactivate
   *  3. resetpassword
   */

  const onModalSubmit = async (data: any) => {
    const actionUrl = (url.api as any)[actionType?.key];
    if (actionUrl) {
      await tableService
        .onPut(
          meta.id,
          filterContext.client.institutionUserId,
          actionUrl,
          actionType?.key,
          data
        )
        .finally(() => {
          setModalOpen(false);
        });
    } else {
      console.error(
        "Invalid table configuration, Please check the relevant table configuration"
      );
    }
  };

  // To form req for sorting
  const onSortChange = (column: string) => {
    setSortDescriptor((prevDescriptor: SortDesc) => {
      if (prevDescriptor?.column === column) {
        return {
          column,
          direction:
            prevDescriptor?.direction === "ascending"
              ? "descending"
              : "ascending"
        };
      } else {
        return {
          column,
          direction: "ascending"
        };
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      const apiUrl = url?.api?.get;
      const results = await tableService.onGet(apiUrl, meta.id, {
        userId: filterContext.client.institutionUserId,
        instituteId: filterContext.client.institutionId,
        pageNumber: page,
        itemsPerPage: rowsPerPage,
        searchBy: filterValue,
        sortColumn: sortDescriptor?.column,
        sortValue:
          sortDescriptor?.direction === "descending"
            ? "DESC"
            : sortDescriptor?.direction === "ascending"
              ? "ASC"
              : "",
        filterColumn: filters?.filterColumn,
        filterValue: filters?.filterValues,
        ...reportFilters
      });
      if(results){
        setData(results);
        setItems(results?.rows);
        setIsLoading(false); // Stop loading
      }
     
    };
    if (
      filterContext &&
      filterContext.client &&
      filterContext.client.institutionUserId &&
      filterContext.client.institutionId
    ) {
      fetchData();
    }
  }, [
    url,
    meta.id,
    rowsPerPage,
    page,
    filterValue,
    filterContext,
    sortDescriptor,
    filters,
    reportFilters
  ]);

  const renderCell = useCallback(
    (row: any, columnKey: any, type: any) => {
      // To remove deactivate option
      let filteredActions = actions;
      if (row?.status === 0) {
        filteredActions = filteredActions?.filter(
          (action: any) => action.key !== "deactivate"
        );
      }
      // To remove rfid option based on rfidStatus
      if (row?.rfid) {
        filteredActions = filteredActions?.filter(
          (action: any) => action.key !== "reset_rfid"
        );
      }

      const cellValue = row[columnKey];
      return (
        <NeoCells
          actions={filteredActions?.filter(action => action.isVisible)}
          handleAction={handleAction}
          row={row}
          columnKey={columnKey}
          cellValue={cellValue}
          type={type}
          meta={meta}
        />
      );
    },
    [actions, handleAction, meta]
  );

  const applyFilters = (newFilters: any) => {
    setFilters(newFilters);
  };

  const applyReportFilters = (reportReq: any) => {
    setReportFilters(reportReq);
  };
  const topContent = useMemo(() => {
    return (
      <NeoTableHeader
        onClear={onClear}
        onSearchChange={onSearchChange}
        filterValue={filterValue}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        statusOptions={statusOptions}
        count={data?.meta?.totalRecords!}
        meta={meta}
        url={url}
        onApplyFilters={applyFilters}
        onRowsPerPageChange={onRowsPerPageChange}
        onSortChange={onSortChange}
        activeFilters={activeFilters}
        reportRequest={applyReportFilters}
        reportFilters = {reportFilters}
      ></NeoTableHeader>
    );
  }, [
    onClear,
    onSearchChange,
    filterValue,
    statusFilter,
    data,
    meta,
    onRowsPerPageChange
  ]);

  const bottomContent = useMemo(() => {
    return (
      <NeoTableFooter
        meta={meta}
        selectedKeys={selectedKeys}
        items={data?.rows!}
        totalPages={data?.meta?.totalPages}
        page={data?.meta?.currentPage}
        setPage={setPage}
        hasSearchFilter={isLoading}
      ></NeoTableFooter>
    );
  }, [selectedKeys, data, setPage, hasSearchFilter, meta]);

  return (
    <>
      <MasterTable
        aria-label="neo-table"
        selectionMode={meta.hasCheckbox ? "multiple" : "none"}
        bottomContent={meta.hasFooter ? bottomContent : null}
        topContent={meta.hasHeader ? topContent : null}
        topContentPlacement="inside"
        bottomContentPlacement="inside"
        onRowAction={handleRowAction}
        isHeaderSticky
        classNames={{
          wrapper: `px-5 ${style.height}`,
          th: "bg-white shadow-none first:rounded-l-none last:rounded-r-none tr:shadow-none",
          tr: "border-solid border-b-1 border-default-200"
        }}
        // hoverable={true}
        selectedKeys={selectedKeys}
        sortDescriptor={sortDescriptor}
        onSelectionChange={setSelectedKeys}
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={headerColumns}>
          {(column: NeoColumnType) => (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "center" : "start"}
              allowsSorting={column.sortable}
              //  children={column.type}
              onClick={() => onSortChange(column.uid)}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          loadingContent={<Spinner color="default" />}
          emptyContent={!isLoading ? "No Records found" : ""}
          items={items}
        >
          {items.map((item: any, index: number) => (
            <TableRow key={index} className="hover:bg-gray-100 hover:text-black">
              {headerColumns.map(column => (
                <TableCell key={column.uid}>
                  {renderCell(item, column?.uid, column?.type)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MasterTable>

      <NeoModal
        actionType={actionType?.key}
        onOpen={modalOpen}
        onClose={onModalClose}
        onModalSubmit={onModalSubmit}
        hasActions={
          actionType?.key == "delete" ||
          actionType?.key == "deactivate" ||
          actionType?.key == "resetpassword"
            ? true
            : false
        }
        selectedRow={rowsData}
      ></NeoModal>
    </>
  );
};

export const MasterTable = extendVariants(Table, {
  variants: {
    radius: {
      none: {
        wrapper: "rounded-none"
      },
      sm: {
        wrapper: "rounded-[8px]"
      },
      md: {
        wrapper: "rounded-[8px]"
      },
      lg: {
        wrapper: "rounded-[8px]"
      }
    },
    shadow: {
      none: {
        wrapper: "shadow-none"
      },
      sm: {
        wrapper: "shadow-small"
      },
      md: {
        wrapper: "shadow-small"
      },
      lg: {
        wrapper: "shadow-small"
      }
    },
    isHeaderSticky: {
      true: {
        thead:
          "sticky top-0 z-20 [&>tr]:first:shadow-none [&>tr]:first:border-solid [&>tr]:first:border-b-2 [&>tr]:first:border-default-200"
      }
    }
  },
  defaultVariants: {
    layout: "auto",
    shadow: "sm",
    radius: "lg",
    color: "default"
  }
});
