"use client";

import {SenderTypes} from "@/data/neo-ai-dashboard/chart-configs/chart.config";
import {Spinner} from "@nextui-org/react";
import {useEffect, useState} from "react";

export const ChatBody: React.FC<ChatBodyProps> = ({isLoading, messages}) => {
  const [loadingText] = useState([
    "Analyzing query...",
    "Processing query...",
    "Generating report...",
    "nearly finished..."
  ]);
  const [index, setIndex] = useState(0);
  const [currentText, setCurrentText] = useState(loadingText[0]);

  //We Changing the loading text while waiting the API response
  const changeLoadingText = () => {
    if (isLoading) {
      if (index < loadingText.length - 1) {
        setIndex(prevIndex => prevIndex + 1);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      isLoading ? changeLoadingText() : setIndex(0);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [isLoading, index, loadingText.length]);

  useEffect(() => {
    setCurrentText(loadingText[index]);
  }, [index, loadingText]);

  const messagesLength = messages?.length || 0;

  return (
    <>
      {messages?.map((message: Message, index: number) => (
        <div
          key={index}
          className={`mb-4 flex items-end ${message?.sender === "user" ? "justify-end" : "justify-start"}`}
        >
          {message?.sender === SenderTypes.BOT && (
            <div className="flex items-start space-x-2">
              <div className="h-10 w-10 rounded-full bg-gray-100 p-2">
                <img
                  className="h-full w-full object-contain"
                  src="/images/IconLogo.svg"
                  alt="boticon"
                />
              </div>
              <div className="grid grid-cols-1">
                <span className="mb-1 text-sm font-bold text-gray-500">
                  NeoBot
                </span>
                {index === messagesLength - 1 && isLoading ? (
                  <div className="flex max-w-xs items-center break-words rounded-r-lg rounded-t-lg bg-blue-50 p-2 text-black">
                    <Spinner size="sm" color="primary" />
                    <span className="ms-2">{currentText}</span>
                  </div>
                ) : (
                  <div className="max-w-xs break-words rounded-r-lg rounded-t-lg bg-gray-100 p-2 text-black">
                    {message?.message}
                  </div>
                )}
              </div>
            </div>
          )}
          {message?.sender === SenderTypes.USER && (
            <div className="max-w-xs break-words rounded-l-lg rounded-t-lg bg-blue-500 p-2 text-white">
              {message?.message}
            </div>
          )}
        </div>
      ))}
    </>
  );
};
