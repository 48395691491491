"use client";
import {NeoButton, NeoSelect} from "@/components/forms";
import {useNeoContext} from "@/context/neo/neo.context";
import {VehicleFilterConfig} from "@/data/vehicle/filter-config";
import {Image, SelectItem} from "@nextui-org/react";
import {useForm} from "react-hook-form";

/**
 * TODO: @selva,@surya,@arun
 * Create a Validation Schema if you have a form & inject them
 */
export const VehiclesFilter: React.FC<VehiclesFilterProps> = ({onSubmit}) => {
  const {register, handleSubmit, setValue} = useForm();
  const {categories, operators} = useNeoContext();

  const onSubmitEvent = (event: any) => {
    onSubmit(event);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(event => {
          onSubmitEvent(event);
        })}
      >
        <div className="flex w-[370px] flex-col gap-5 p-2">
          <NeoSelect
            multiple={true}
            {...register("vehicleType")}
            label="Vehicle Type"
          >
            {VehicleFilterConfig?.vehicleType?.map(item => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </NeoSelect>
          <NeoSelect {...register("operator")} label="Operator">
            {operators?.map(item => (
              <SelectItem key={item.operatorId} value={item.operatorId}>
                {item.operatorName}
              </SelectItem>
            ))}
          </NeoSelect>
          <NeoSelect {...register("category")} label="Category">
            {categories.map((item: any) => (
              <SelectItem key={item.categoryId} value={item.categoryId}>
                {item.categoryName}
              </SelectItem>
            ))}
          </NeoSelect>
          {/* <NeoSwitchInput
            label="GPS"
            activeLabel="Real Time"
            inActiveLabel="Mobile"
            onValueChange={value => handleSwitchChange("gps", value)}
          />
          <div className="grid grid-cols-2 gap-3">
            <NeoSwitchInput
              label="Ownership"
              activeLabel="Self"
              inActiveLabel="Contract"
              onValueChange={value => handleSwitchChange("ownership", value)}
            />
            <NeoSwitchInput
              label="Status"
              activeLabel="Active"
              inActiveLabel="Inactive"
              onValueChange={value => handleSwitchChange("status", value)}
            />
          </div> */}
          <div className="w-100 flex justify-end">
            <NeoButton
              type="submit"
              color="yellow"
              className="gap-[5px] text-lg"
              startContent={
                <Image
                  className="h-5 w-5"
                  src="/icons/filter.svg"
                  alt="filter"
                />
              }
            >
              Filter
            </NeoButton>
          </div>
        </div>
      </form>
    </>
  );
};
