"use client";
import {NeoHeader} from "@/components/shared";
import {NeoTable} from "@/components/table";
import {UserTableConfig} from "@/data";
import type {NextPage} from "next";

interface IUsers {
  name: string;
  gender: string;
  parentName: string;
  phoneNo: string;
  destination: string;
  rfidNo: string;
  pickup: string;
  drop: string;
  userType: string;
  status: string;
}

export const Users: NextPage = function () {
  const data = UserTableConfig;
  return (
    <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
      <div className="mb-4 flex-row px-3">
        <NeoHeader message={data.title}></NeoHeader>
      </div>
      <div className="flex-row">
        <NeoTable table={data.table}></NeoTable>
      </div>
    </div>
  );
};
