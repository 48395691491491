export const BarChatConfig: ApexCharts.ApexOptions = {
  chart: {
    id: "basic-bar"
  },
  xaxis: {
    categories: [],
    title: {
      text: ""
    }
  },
  yaxis: {
    title: {
      text: ""
    }
  },
  series: []
};

export const LineChatConfig: ApexCharts.ApexOptions = {
  chart: {
    id: "basic-Line"
  },
  xaxis: {
    categories: []
  },
  yaxis: {
    title: {
      text: ""
    }
  },
  series: []
};

export const ScatterChatConfig: ApexCharts.ApexOptions = {
  chart: {
    id: "basic-scatter"
  },
  xaxis: {
    categories: [],
    title: {
      text: ""
    }
  },
  yaxis: {
    title: {
      text: ""
    }
  },
  series: []
};

export const AreaChatConfig: ApexCharts.ApexOptions = {
  chart: {
    id: "basic-Area"
  },
  xaxis: {
    categories: []
  },
  yaxis: {
    title: {
      text: ""
    }
  },
  series: []
};

export enum SenderTypes {
  USER = "user",
  BOT = "bot"
}

export const ChartTypeConfigs: ChartTypeConfigs = {
  bar: BarChatConfig,
  line: LineChatConfig,
  scatter: ScatterChatConfig,
  area: AreaChatConfig
};

export enum AllVehicles {
  ALL = "All Vehicles"
}
