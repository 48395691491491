"use client";
import {axiosInstance} from "@/app/api";
import {ProfileData} from "@/data";
import {mapFields} from "@/libs/helper";

const onGetProfile = async (): Promise<any> => {
  const profile = ProfileData;
  return await axiosInstance()
    .get(`api/profile`)
    .then((response: any) => {
      if (response.data.status === "success") {
        profile.card.fields = mapFields(
          response?.data?.data.usersMeta,
          profile?.card?.fields
        ) as ProfileModule.Field[];
        return profile;
      } else {
        return profile;
      }
    })
    .finally(() => {
      return profile;
    });
};

const changePassword = async (body: any): Promise<any> => {
  return await axiosInstance()
    .post("", body)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {});
};

const changeMobileNumber = async (body: any): Promise<any> => {
  return await axiosInstance()
    .post("", body)
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {});
};

export const profileService = {
  onGetProfile,
  changePassword,
  changeMobileNumber
};
