import {AlertIcon, NeoDescription, NeoSubHeader} from "../shared";

export const DeleteModal = () => {
  return (
    <div className="flex flex-col items-center gap-4 pt-4">
      <AlertIcon size={105}></AlertIcon>
      <NeoSubHeader message="Are you Sure?"></NeoSubHeader>
      <NeoDescription message="Do you really want to delete? This process cannot be undone"></NeoDescription>
    </div>
  );
};
