"use client";

import {NeoCard} from "@/components/shared";
import {TimeLineConfig} from "@/data";
import {Label} from "flowbite-react";
import {TbAlarmFilled} from "react-icons/tb";

export const TimelineView: React.FC<TimeLineProps> = function () {
  const dataSource = TimeLineConfig || [];
  return (
    <div className="h-full w-full p-5">
      {dataSource.map((item, index) => (
        <div
          key={index}
          className={`relative flex w-full items-start justify-center gap-5 px-3 py-5 ${index % 2 == 1 ? "flex-row-reverse" : ""}`}
        >
          <div
            className={`flex min-w-[40%] flex-col px-3 ${index % 2 == 1 ? "items-start" : "items-end"}`}
          >
            <Label className="text-xl" value={item?.name} />
            <div className="flex items-center gap-1">
              <span className="text-neo-green">
                <TbAlarmFilled />
              </span>
              <Label
                className="text-xs text-neo-gray"
                value={item.updatedDate}
              />
            </div>
          </div>
          <div className="flex h-6 w-6 justify-center rounded-full bg-black">
            {item.isActive && (
              <div className="mt-[25%] h-2/4 w-2/4 rounded-full bg-neo-yellow"></div>
            )}
            <div
              id="active-line"
              className={`absolute z-[-1] h-full w-0.5 ${item.isActive ? "bg-neo-black" : "bg-gray-300"}`}
            ></div>
          </div>
          <NeoCard className="min-w-[40%] p-3">
            <div className="flex items-center gap-1 py-1 ">
              <span className="text-neo-gray">
                <TbAlarmFilled />
              </span>
              <Label
                className="text-sm text-neo-gray"
                value={item.updatedDate}
              />
            </div>
            <div className="grid grid-cols-1">
              {item?.students?.map((x, i) => (
                <Label
                  key={i}
                  className={`text-base ${x.swipe ? 'text-neo-black' : 'text-neo-gray'}`}
                  value={x.name}
                />
              ))}
            </div>
          </NeoCard>
        </div>
      ))}
    </div>
  );
};
