"use client";
import {
  NeoBreadcrumb,
  NeoCard,
  NeoHeader,
  NeoSubHeader
} from "@/components/shared";
import {useNeoContext} from "@/context/neo/neo.context";
import {decryptUrl} from "@/libs/helper";
import {vehicleService} from "@/service/vehicle.service";
import {CardBody, CardHeader, Spinner} from "@nextui-org/react";
import {NextPage} from "next";
import {useEffect, useState} from "react";
import {VehicleComplianceForm} from "./vehicle-compliance";
import {VehicleForm} from "./vehicle-form";
const sKey: string = process.env.NEXT_PUBLIC_NEO_SECRET || "";
interface ManageVehicleProps {
  params: PageProps;
}

interface PageProps {
  id: number | string;
  action: string;
  slug: string;
}

export const ManageVehicle: NextPage<ManageVehicleProps> = function ({params}) {
  const {filterContext} = useNeoContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [header, setHeader] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const id = await decryptUrl(params.id as string, sKey);
      setLoading(true);
      await vehicleService
        .onFetchVehicleDetails(id as string, params.action, params.slug)
        .then((response: any) => {
          if (params.action == "create") {
            setHeader(
              params.slug === "vehicle" ? "Create Vehicle" : "Create Compliance"
            );
          } else {
            setHeader(
              params.slug === "vehicle" ? "Edit Vehicle" : "Edit Compliance"
            );
          }
          setData(response);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (filterContext.client.institutionId) {
      fetchData();
    }
  }, [params, filterContext]);
  return (
    <div className="flex min-h-[calc(100vh-63px)] w-full flex-col p-5">
      <div className="mb-5 flex-row">
        <NeoHeader message="Vehicles"></NeoHeader>
      </div>
      <div className="mb-5 flex-row">
        <NeoBreadcrumb menus={breadCrumb}></NeoBreadcrumb>
      </div>
      <div className="w-full flex-row">
        <NeoCard className="mb-5">
          <CardHeader>
            <div className="px-3 py-2">
              <NeoSubHeader message={header}></NeoSubHeader>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Spinner color="default" />
            ) : params.slug === "vehicle" ? (
              <VehicleForm client={filterContext.client} items={data} />
            ) : (
              <VehicleComplianceForm
                client={filterContext.client}
                items={data}
              ></VehicleComplianceForm>
            )}
          </CardBody>
        </NeoCard>
      </div>
    </div>
  );
};

const breadCrumb = [
  {
    link: "/vehicles",
    label: "Vehicle"
  },
  {
    link: "",
    label: "Edit Vehicle"
  }
];
