/**
 * @important
 * Export the component which you will use in src/app/(page) or src/app/(auth)
 */
export {ForgotPassword} from "./auth/forgot-password/forgot-password";
export {Login} from "./auth/login/login";
export {ResetPassword} from "./auth/reset-password/reset-password";

export {Profile} from "./profile/profile";
export {ProfileForm} from "./profile/profile-form";
export {SmartEdit} from "./users/smart-edit";
export {Users} from "./users/users";
export {ViewUser} from "./users/view-user";

export {Configuration} from "./configuration/configuration";
export {Dashboard} from "./dashboard/dashboard";
export {Drivers} from "./drivers/drivers";
export {Live} from "./live/live";
export {Notification} from "./notification/notification";
export {Reports} from "./reports/reports";
export {Routes} from "./routes/routes";
export {ManageVehicle} from "./vehicles/manage-vehicle";
export {Vehicles} from "./vehicles/vehicles";
export {ViewVehicle} from "./vehicles/view-vehicle";
