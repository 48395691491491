"use client";
import {NeoHeader} from "@/components/shared";
import {NeoTable} from "@/components/table";
import {VehicleTableConfig} from "@/data/vehicle/table.config";
import type {NextPage} from "next";

export const Vehicles: NextPage = function () {
  const data = VehicleTableConfig;
  return (
    <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
      <div className="mb-4 flex-row px-3">
        <NeoHeader message="Vehicles"></NeoHeader>
      </div>
      <div className="flex-row">
        <NeoTable table={data.table}></NeoTable>
      </div>
    </div>
  );
};
