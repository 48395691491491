"use client";
import React, {useEffect, useMemo, useState} from "react";

interface TypewriterProps {
  text: string;
  isTyping?: boolean;
}

const Typewriter: React.FC<TypewriterProps> = ({text, isTyping = true}) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const memoizedText = useMemo(() => text, [text]);

  useEffect(() => {
    if (!isTyping) {
      setDisplayText("");
      setCurrentIndex(0);
    }
  }, [isTyping]);

  useEffect(() => {
    if (isTyping && currentIndex < memoizedText.length) {
      const interval = setInterval(() => {
        setDisplayText(prevText => prevText + memoizedText[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, 20); // Adjust the speed here if needed

      return () => clearInterval(interval);
    } else {
      setDisplayText(memoizedText);
    }
  }, [currentIndex, memoizedText, isTyping]);

  return <span dangerouslySetInnerHTML={{__html: displayText}}></span>;
};

export default Typewriter;
