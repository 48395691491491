"use client";
import {NeoButton, NeoTextBox} from "@/components/forms";
import {NeoError, NeoHeader, NeoLabel} from "@/components/shared";
import {useNeoContext} from "@/context/neo/neo.context";
import {RFIDdetails} from "@/data/users/rfid.config";
import {userService} from "@/service/users.service";
import {yupResolver} from "@hookform/resolvers/yup";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
interface RfidEditProps {
  onClose: any;
  onSubmitPayload?: (payload: any) => void | undefined;
  selectedRow: any;
}
interface IResetRfid {
  rfid?: number;
}
export const RfidEdit: React.FC<RfidEditProps> = ({onClose, selectedRow}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [details, setDetails] = useState(RFIDdetails);
  const {filterContext} = useNeoContext();

  const validationSchema = yup.object().shape({
    rfid: yup
      .number()
      .nullable()
      .required("RFID is required")
      .typeError("RFID must be a number")
  });
  const {
    register,
    handleSubmit,
    setError,
    formState: {errors}
  } = useForm<any>({
    mode: "onTouched",
    resolver: yupResolver(validationSchema)
  });
  const onSubmit = async (data: IResetRfid) => {
    const payload = {
      userId: "aabwtqK",
      rfid: data?.rfid
    };

    await userService
      .onUserAction(
        "api/users/rfid/update",
        filterContext.client.institutionUserId,
        "reset_rfid",
        payload
      )
      .then((response: any) => {
        if (response?.data?.status === "success") {
          onClose();
        } else if (response?.data?.status !== "success") {
          setErrorMessage(
            "Sorry!, We have technical issue please try again later"
          );
        }
      })
      .catch(err => {
        if (err) {
          setError("rfid", {type: "server", message: err?.message});
        }
      })
      .finally(() => {
        // setLoading(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      });
  };
  useEffect(() => {
    // Function to fetch data from the service
    const fetchData = async () => {
      try {
        const updatedDetails = details.map(detail => {
          switch (detail.label) {
            case "Parent Name":
              return {...detail, value: selectedRow?.parentName};
            case "Student Name":
              return {...detail, value: selectedRow?.studentName};
            case "Phone Number":
              return {...detail, value: selectedRow?.phone};
            case "Class":
              return {...detail, value: selectedRow?.className};
            case "Old RFID NO":
              return {...detail, value: selectedRow?.rfid};
            default:
              return detail;
          }
        });
        // Update the state with the new details
        setDetails(updatedDetails);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, [selectedRow]);
  return (
    <>
      <div className="my-5 px-4">
        <NeoHeader message="RFID Reset"></NeoHeader>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="px-3">
        <div className="py-2">
          <div className="flex-row px-3">
            <div className="flex flex-wrap">
              {details.map((field: any, key: any) => (
                <div className="mb-5 w-1/2" key={key}>
                  <NeoLabel label={field.label} value={field.value}></NeoLabel>
                </div>
              ))}
              <div className="w-1/2">
                <label className="block text-gray-500">RFID No</label>
                <div className="w-full py-2">
                  <NeoTextBox
                    id="rfid"
                    placeholder="Enter RFID"
                    color={errors.rfid ? "danger" : "default"}
                    isInvalid={errors.rfid ? true : false}
                    errorMessage={errors.rfid && `${errors.rfid.message}`}
                    {...register("rfid")}
                  />
                </div>
              </div>
            </div>
          </div>
          {errorMessage ? <NeoError message={errorMessage}></NeoError> : null}
          <div className="mt-2 flex items-center justify-end p-4">
            <NeoButton color="white" onPress={onClose}>
              Close
            </NeoButton>
            <NeoButton className="mx-5" type="submit" color="black">
              Save
            </NeoButton>
          </div>
        </div>
      </form>
    </>
  );
};
