import {SystemError} from "@/libs/consts";
import {GPSTracker} from "@/libs/enums/live";
import {trackingService} from "@/service/tracking.service";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import toast from "react-hot-toast";

const LiveContext = createContext<LiveContextProps>({} as LiveContextProps);
type Props = {
  children: React.ReactNode;
};
type GpsLocations = {
  [vehicleId: string]: {
    key: number;
    locations: NeoMap[];
    refRouteDetails: NeoMap[];
    userBoardings: NeoMap[];
    clearRouteData: boolean;
    trackerStatus: number;
  };
};

type LiveContextProps = {
  selectedVehicle: TrackingInfo | null;
  setSelectedVehicle: React.Dispatch<React.SetStateAction<TrackingInfo | null>>;
  countDown: number;
  setCountDown: React.Dispatch<React.SetStateAction<number>>;
  gpsLocations: GpsLocations | null;
  setGpsLocations: React.Dispatch<React.SetStateAction<GpsLocations>>;
  gpsTracker: GPSTracker;
  setGpsTracker: React.Dispatch<React.SetStateAction<GPSTracker>>;
  meta: IVehiclesMeta;
  setMeta: React.Dispatch<React.SetStateAction<IVehiclesMeta>>;
  filterValues: FeatureFilterValueProps;
  setFilterValues: React.Dispatch<
    React.SetStateAction<FeatureFilterValueProps>
  >;
  vehicleRoutes: vehicleRouteResProp;
};

export const useLiveContext = () => useContext<LiveContextProps>(LiveContext);

export const LiveContextProvider = ({children}: Props) => {
  const [selectedVehicle, setSelectedVehicle] = useState<TrackingInfo | null>(
    null
  );
  const [meta, setMeta] = useState<IVehiclesMeta>({});
  const [gpsLocations, setGpsLocations] = useState<{
    [vehicleId: string]: {
      key: number;
      locations: NeoMap[];
      refRouteDetails: NeoMap[];
      userBoardings: NeoMap[];
      clearRouteData: boolean;
      trackerStatus: number;
    };
  }>(null as any);

  const routes = useRef<{
    [vehicleId: string]: {
      key: number;
    };
  }>(null as any);

  const [countDown, setCountDown] = useState<number>(6);
  const [gpsTracker, setGpsTracker] = useState<GPSTracker>(GPSTracker.NEUTRAL);
  const selectedVehicleId = useRef<string>("");
  const institutionUserId = useRef<string>("");
  const returnBackKey = useRef<number>(-1);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const [filterValues, setFilterValues] = useState<FeatureFilterValueProps>(
    {} as FeatureFilterValueProps
  );
  const [vehicleRoutes, setVehicleRoutes] = useState<vehicleRouteResProp>(
    {} as vehicleRouteResProp
  );
  const resetGpsTracking = () => {
    clearInterval(intervalId.current!);
    setCountDown(6);
    returnBackKey.current = -1;
  };

  /**
   * WARNING
   *
   * Do not touch this use effect dependencies unless it really needed,
   *  it will create lot of unnecessary service calls which will leads to application memory issue
   *
   */

  const updateGps = useMemo(
    () =>
      (
        vehicleId: string,
        key: number,
        data: NeoMap[],
        refRouteDetails: RefRouteDetail[] | undefined,
        userBoardings: NeoMap[] | undefined,
        clearRouteData: boolean,
        trackerStatus: number
      ) => {
        // clearRouteData = false;
        setGpsLocations((prev: any) => {
          const gpsCoordinates = {
            ...prev,
            [vehicleId]: {
              key: key,
              locations: clearRouteData
                ? [...data]
                : prev && prev[vehicleId]
                  ? [...prev[vehicleId].locations, ...data]
                  : [...data],
              refRouteDetails: refRouteDetails
                ? refRouteDetails
                : prev && prev[vehicleId]?.refRouteDetails,
              userBoardings: userBoardings
                ? userBoardings
                : prev && prev[vehicleId]?.userBoardings,
              trackerStatus: trackerStatus
            }
          };
          //console.table(gpsCoordinates);
          //console.log("context", gpsCoordinates.length);
          routes.current = gpsCoordinates;
          return gpsCoordinates;
        });
      },
    [setGpsLocations]
  );

  /**
   * WARNING
   *
   * Do not touch this use effect dependencies unless it really needed,
   *  it will create lot of unnecessary service calls which will leads to application memory issue
   *
   */

  const fetchData = useCallback(
    async (selectedVehicleId: string, institutionUserId: string) => {
      if (gpsTracker === GPSTracker.START) {
        try {
          const key =
            (routes.current && routes.current[selectedVehicleId]?.key) || -1;
          const response: IRouteTracking =
            await trackingService.onGetTrackingStatus(
              selectedVehicleId,
              institutionUserId,
              key
            );
          if (
            response.trackerApi === "failure" ||
            response.referenceApi === "failure"
          ) {
            setGpsTracker(GPSTracker.STOP);
          }
          const newCoordinates = response?.locations ?? [];
          returnBackKey.current = response?.returnBackKey!;
          updateGps(
            selectedVehicleId,
            returnBackKey.current,
            newCoordinates,
            response?.refRouteDetails,
            response?.userBoardings,
            response.clearRouteData,
            response.trackerStatus
          );
        } catch (error) {
          setGpsTracker(GPSTracker.STOP);
          console.error("Error fetching data:", error);
          toast.error(SystemError);
        }
      } else {
        setGpsTracker(GPSTracker.STOP);
        console.warn("GPS Stopped");
      }
    },
    [gpsTracker, updateGps]
  );

  /**
   * WARNING
   *
   * Do not touch this use effect dependencies unless it really needed,
   *  it will create lot of unnecessary service calls which will leads to application memory issue
   *
   */

  useEffect(() => {
    clearInterval(intervalId.current!);
    if (selectedVehicle && selectedVehicle != null && gpsTracker) {
      clearInterval(intervalId.current!);
      selectedVehicleId.current = selectedVehicle.vehicleId;
      institutionUserId.current = selectedVehicle.institutionUserId;
      fetchData(selectedVehicleId.current, institutionUserId.current);
      intervalId.current = setInterval(() => {
        fetchData(selectedVehicleId.current, institutionUserId.current);
      }, 5000);
    }
    if (gpsTracker === GPSTracker.STOP || gpsTracker === GPSTracker.PLAYBACK) {
      resetGpsTracking();
    }
    return () => {
      resetGpsTracking();
    };
  }, [selectedVehicle, fetchData, gpsTracker]);

  const fetchRouteData = useCallback(
    async (body: getRouteProp) => {
      const response: vehicleRouteResProp =
        await trackingService.onGetVehicleRoutes(body);
      setVehicleRoutes(response);
    },
    [selectedVehicle]
  );

  useEffect(() => {
    if (selectedVehicle?.institutionUserId && selectedVehicle?.vehicleId) {
      const constractBody = async () => {
        const body: getRouteProp = {
          userid: selectedVehicle?.institutionUserId,
          vehicleid: selectedVehicle?.vehicleId,
          trip_type: [0, 1]
        };
        await fetchRouteData(body);
      };
      constractBody();
    }
  }, [selectedVehicle]);

  return (
    <LiveContext.Provider
      value={{
        selectedVehicle,
        setSelectedVehicle,
        countDown,
        setCountDown,
        gpsLocations,
        setGpsLocations,
        gpsTracker,
        setGpsTracker,
        meta,
        setMeta,
        filterValues,
        setFilterValues,
        vehicleRoutes
      }}
    >
      {children}
    </LiveContext.Provider>
  );
};
