import {Image} from "@nextui-org/react";

export const Cover: React.FC = () => {
  return (
    <div className="relative h-screen w-1/2">
      <Image
        src="/images/cover.svg"
        alt="Neo Live"
        className="h-screen w-screen"
        radius="none"
        style={{objectFit: "cover"}}
      />
    </div>
  );
};
