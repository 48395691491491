import {createContext, useContext, useState} from "react";
const AllVehicleContext = createContext<AllVehicleContextProps>(
  {} as AllVehicleContextProps
);
type AllVehicleContextProps = {
  data: TrackingInfo[];
  setData: React.Dispatch<React.SetStateAction<TrackingInfo[] | []>>;
  tabVehicles: TrackingInfo[];
  setTabVehicles: React.Dispatch<React.SetStateAction<TrackingInfo[] | []>>;
};
type Props = {
  children: React.ReactNode;
};
export const AllVehicleContextProvider = ({children}: Props) => {
  const [data, setData] = useState<TrackingInfo[] | []>([]);
  const [tabVehicles, setTabVehicles] = useState<TrackingInfo[] | []>([]);

  return (
    <AllVehicleContext.Provider
      value={{
        data,
        setData,
        tabVehicles,
        setTabVehicles
      }}
    >
      {children}
    </AllVehicleContext.Provider>
  );
};
export const useAllVehicleContext = () =>
  useContext<AllVehicleContextProps>(AllVehicleContext);
