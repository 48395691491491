"use client";
import {
  ColorPicker,
  NeoButton,
  NeoSelect,
  NeoTextBox
} from "@/components/forms";
import {useNeoContext} from "@/context/neo/neo.context";
import {VehicleFilterConfig} from "@/data/vehicle/filter-config";
import {vehicleService} from "@/service/vehicle.service";
import {yupResolver} from "@hookform/resolvers/yup";
import {SelectItem} from "@nextui-org/react";
import {useRouter} from "next/navigation";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {AiOutlineLoading} from "react-icons/ai";
import * as yup from "yup";

interface IVehicleForm {
  shortName?: string;
  ownership?: boolean;
  gpsSystem?: boolean;
  gps?: boolean;
  hardwareNo?: string;
  vehicleType?: string;
  fuelType?: string;
  vehicleCategory?: string;
  seatingCapacity: string;
  operator?: string;
  contractStartDate?: any;
  contractEndDate?: any;
  driverName?: string;
  driverPhoneNo?: string;
  maxTravelDuration?: string;
  maxTravelDistance?: string;
  startingKm?: string;
  vehiclePurchasedDate?: any;
  color: string;
  releasedDate?: any;
}

type VehicleFormProps = {
  items: any;
  client: Client;
};
export const VehicleForm: React.FC<VehicleFormProps> = ({items, client}) => {
  const [loading, setLoading] = useState(false);
  const selectedClient = useRef(client);
  const [defaultValues, setDefaultvalue] = useState(items?.data);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {categories, operators} = useNeoContext();
  const [opertorDriver, setOperatorDriver] = useState([
    {userId: "", name: "", driverId: ""}
  ]);
  useEffect(() => {
    setValue("color", defaultValues?.color);
    setDriverDropdown(defaultValues?.operator);
  }, [items]);

  const handleOperatorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDriverDropdown(e.target.value);
    setValue("operator", e.target.value);
  };
  const setDriverDropdown = (operotId: string) => {
    const selectedOpertorVal = operators.find(
      c => c.operatorId === parseInt(operotId)
    );
    if (selectedOpertorVal && selectedOpertorVal.drivers) {
      const updatedDrivers = selectedOpertorVal?.drivers.map(driver => ({
        userId: driver.userId,
        name: driver.name,
        driverId: driver.driverId.toString() // Convert driverId to string
      }));
      setOperatorDriver(updatedDrivers);
    }
  };
  const validationSchema = yup.object().shape({
    shortName: yup.string(),
    ownership: yup.boolean(),
    gpsSystem: yup.boolean(),
    gps: yup.boolean(),
    hardwareNo: yup.string(),
    vehicleType: yup.string(),
    fuelType: yup.string(),
    vehicleCategory: yup.string(),
    seatingCapacity: yup.string().required("Please enter vehicle capacity"),
    operator: yup.string(),
    contractStartDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    contractEndDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
      .min(
        yup.ref("contractStartDate"),
        "Contract end date should be greater than contract start date"
      ),
    driverName: yup.string(),
    driverPhoneNo: yup.string(),
    maxTravelDuration: yup.string(),
    maxTravelDistance: yup.string(),
    startingKm: yup.string(),
    vehiclePurchasedDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    color: yup.string().required("Please select color"),
    releasedDate: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
  });
  const {
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    control,
    setValue,
    setError
  } = useForm<IVehicleForm>({
    defaultValues,
    mode: "onTouched",
    resolver: yupResolver(validationSchema)
  });
  const {push} = useRouter();
  const onSubmit = async (data: IVehicleForm) => {
    setLoading(true);
    const colr = data.color;
    //remove hash from color
    data.color = colr ? colr.replace("#", "") : "";
    //var clr = colr ? colr.slice() : "";
    const additionalPrimaryData = {
      insId: selectedClient.current.institutionId,
      trackerId: items?.trackerId,
      currentUser: selectedClient.current.institutionUserId
    };
    await vehicleService
      .onEditVehicle(
        "api/vehicles/update",
        data,
        additionalPrimaryData,
        "vehicle"
      )
      .then(response => {
        if (response?.data?.status === "success") {
          push(`vehicle-compliance`);
        } else {
          setErrorMessage(
            "Sorry!, We have technical issue please try again later"
          );
        }
      })
      .catch(err => {
        if (err?.errors?.length > 0) {
          err?.errors.forEach(
            ({field, message}: {field: any; message: string}) => {
              setError(field, {type: "server", message: message});
            }
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      });
  };

  // Handle switch input change and update form value
  const handleSwitchChange = (name: any, value: boolean) => {
    setValue(name, value);
  };
  const onDateChange = (name: any, event: any) => {
    setValue(name, event);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full px-5">
      <div className="grid w-full grid-cols-4 gap-9">
        <NeoTextBox
          disabled
          label="Short Name"
          id="shortName"
          placeholder="Please Enter Short Name"
          {...register("shortName")}
          defaultValue={defaultValues?.shortName}
        />

        {/* <Controller
          name="ownership"
          control={control}
          defaultValue={defaultValues?.ownership}
          render={({field}) => (
            <NeoSwitchInput
              label="Ownership"
              activeLabel="Self"
              inActiveLabel="Contract "
              onValueChange={value => handleSwitchChange("ownership", value)}
            />
          )}
        ></Controller> */}

        {/* <Controller
          name="gpsSystem"
          control={control}
          defaultValue={defaultValues?.gpsSystem}
          render={({field}) => (
            <NeoSwitchInput
              label="GPS System"
              activeLabel="Enabled"
              inActiveLabel="Disabled"
              onValueChange={value => handleSwitchChange("gpsSystem", value)}
            />
          )}
        ></Controller> */}

        {/* <Controller
          name="gps"
          control={control}
          defaultValue={defaultValues?.gps}
          render={({field}) => (
            <NeoSwitchInput
              label="GPS"
              activeLabel="Real Time"
              inActiveLabel="Mobile"
              onValueChange={value => handleSwitchChange("gps", value)}
            />
          )}
        ></Controller> */}

        {/* <NeoTextBox
          label="Hardware No"
          id="hardwareNo"
          defaultValue={defaultValues?.hardwareNo}
          placeholder="Please Enter Hardware No"
          color={errors.hardwareNo ? "danger" : "default"}
          isInvalid={errors.hardwareNo ? true : false}
          errorMessage={errors.hardwareNo && `${errors.hardwareNo.message}`}
          {...register("hardwareNo")}
        /> */}

        <Controller
          name="vehicleType"
          control={control}
          render={({field}) => (
            <NeoSelect
              {...field}
              label="Vehicle Type"
              id="Vehicle Type"
              placeholder="Please select type"
              color={errors.vehicleType ? "danger" : "default"}
              isInvalid={errors.vehicleType ? true : false}
              errorMessage={
                errors.vehicleType && `${errors.vehicleType.message}`
              }
              defaultSelectedKeys={[defaultValues?.vehicleType]}
            >
              {VehicleFilterConfig.vehicleType.map(item => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </NeoSelect>
          )}
        ></Controller>

        {/* <Controller
          name="fuelType"
          control={control}
          render={({field}) => (
            <NeoSelect
              label="Fuel Type"
              id="fuelType"
              placeholder="Please select fuel type"
              color={errors.fuelType ? "danger" : "default"}
              isInvalid={errors.fuelType ? true : false}
              errorMessage={errors.fuelType && `${errors.fuelType.message}`}
              {...register("fuelType")}
              defaultSelectedKeys={[defaultValues?.fuelType]}
            >
              {VehicleFilterConfig?.fuleType?.map(item => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </NeoSelect>
          )}
        ></Controller> */}
        <Controller
          name="vehicleCategory"
          control={control}
          render={({field}) => (
            <NeoSelect
              label="Vehicle Category"
              id="vehicleCategory"
              placeholder="Please select category type"
              color={errors.vehicleCategory ? "danger" : "default"}
              isInvalid={errors.vehicleCategory ? true : false}
              errorMessage={
                errors.vehicleCategory && `${errors.vehicleCategory.message}`
              }
              {...register("vehicleCategory")}
              defaultSelectedKeys={[defaultValues?.vehicleCategory]}
            >
              {categories.map((item: any) => (
                <SelectItem key={item.categoryId} value={item.categoryId}>
                  {item.categoryName}
                </SelectItem>
              ))}
            </NeoSelect>
          )}
        ></Controller>
        <NeoTextBox
          label="Seating Capacity"
          id="seatingCapacity"
          defaultValue={defaultValues?.seatingCapacity}
          placeholder="Please Enter Seating Capacity"
          color={errors.seatingCapacity ? "danger" : "default"}
          isInvalid={errors.seatingCapacity ? true : false}
          errorMessage={
            errors.seatingCapacity && `${errors.seatingCapacity.message}`
          }
          {...register("seatingCapacity")}
        />
        <Controller
          name="operator"
          control={control}
          render={({field}) => (
            <NeoSelect
              label="Operator"
              id="operator"
              placeholder="Please select operator"
              color={errors.operator ? "danger" : "default"}
              isInvalid={errors.operator ? true : false}
              errorMessage={errors.operator && `${errors.operator.message}`}
              {...register("operator")}
              defaultSelectedKeys={[defaultValues?.operator]}
              onChange={handleOperatorChange}
            >
              {operators?.map((item: any) => (
                <SelectItem key={item.operatorId} value={item.operatorId}>
                  {item.operatorName}
                </SelectItem>
              ))}
            </NeoSelect>
          )}
        ></Controller>

        {/* <Controller
          name="contractStartDate"
          control={control}
          defaultValue={defaultValues?.contractStartDate}
          render={({field}) => (
            <NeoDatePicker
              label="Contract Start Date"
              errorMessage={errors.contractStartDate?.message}
              handleDateChange={e => onDateChange("contractStartDate", e)}
            />
          )}
        ></Controller> */}

        {/* <Controller
          name="contractEndDate"
          control={control}
          defaultValue={defaultValues?.contractEndDate}
          render={({field}) => (
            <NeoDatePicker
              label="Contract End Date"
              errorMessage={errors.contractEndDate?.message}
              handleDateChange={e => onDateChange("contractEndDate", e)}
            />
          )}
        ></Controller> */}

        <Controller
          name="driverName"
          control={control}
          render={({field}) => (
            <NeoSelect
              label="Driver"
              id="driverName"
              placeholder="Please select driver"
              color={errors.driverName ? "danger" : "default"}
              isInvalid={errors.driverName ? true : false}
              errorMessage={errors.driverName && `${errors.driverName.message}`}
              {...register("driverName")}
              defaultSelectedKeys={[defaultValues?.driverName]}
            >
              {opertorDriver?.map((item: any) => (
                <SelectItem key={item.driverId} value={item.driverId}>
                  {item.name}
                </SelectItem>
              ))}
            </NeoSelect>
          )}
        ></Controller>

        {/* <NeoTextBox
          label="Driver Phone.No"
          id="driverPhoneNo"
          defaultValue={defaultValues?.driverPhoneNo}
          placeholder="Please Enter Driver Name"
          color={errors.driverPhoneNo ? "danger" : "default"}
          isInvalid={errors.driverPhoneNo ? true : false}
          errorMessage={
            errors.driverPhoneNo && `${errors.driverPhoneNo.message}`
          }
          {...register("driverPhoneNo")}
        /> */}

        {/* <NeoTextBox
          label="Max. Travel Duration(HH:MM)"
          id="maxTravelDuration"
          defaultValue={defaultValues?.maxTravelDuration}
          placeholder="Please Enter Operator Name"
          color={errors.maxTravelDuration ? "danger" : "default"}
          isInvalid={errors.maxTravelDuration ? true : false}
          errorMessage={
            errors.maxTravelDuration && `${errors.maxTravelDuration.message}`
          }
          {...register("maxTravelDuration")}
        /> */}

        {/* <NeoTextBox
          label="Max.Travel Distance"
          id="maxTravelDistance"
          defaultValue={defaultValues?.maxTravelDistance}
          placeholder="Please Enter Operator Name"
          color={errors.maxTravelDistance ? "danger" : "default"}
          isInvalid={errors.maxTravelDistance ? true : false}
          errorMessage={
            errors.maxTravelDistance && `${errors.maxTravelDistance.message}`
          }
          {...register("maxTravelDistance")}
        /> */}

        {/* <NeoTextBox
          label="Starting Kms"
          id="startingKm"
          defaultValue={defaultValues?.startingKm}
          placeholder="Please Enter Starting Kms Name"
          color={errors.startingKm ? "danger" : "default"}
          isInvalid={errors.startingKm ? true : false}
          errorMessage={errors.startingKm && `${errors.startingKm.message}`}
          {...register("startingKm")}
        /> */}

        {/* <Controller
          name="vehiclePurchasedDate"
          control={control}
          defaultValue={defaultValues?.vehiclePurchasedDate}
          render={({field}) => (
            <NeoDatePicker
              label="Vehicle Purchased Date"
              errorMessage={errors.vehiclePurchasedDate?.message}
              handleDateChange={e => onDateChange("vehiclePurchasedDate", e)}
            />
          )}
        ></Controller> */}

        <ColorPicker
          value={defaultValues?.color}
          errorMessage={errors.color?.message}
          handleChange={color => setValue("color", color)}
          label="Choose Color"
        />

        {/* <Controller
          name="releasedDate"
          control={control}
          defaultValue={defaultValues?.releasedDate}
          render={({field}) => (
            <NeoDatePicker
              label="Vehicle Released Date"
              errorMessage={errors.releasedDate?.message}
            />
          )}
        ></Controller> */}
      </div>
      <div className="flex items-center justify-end p-4">
        <NeoButton
          className="mx-5 mt-2"
          type="submit"
          disabled={isSubmitting}
          isLoading={loading}
          spinner={
            <AiOutlineLoading size="xs" className="h-6 w-6 animate-spin" />
          }
          color="black"
        >
          Next
        </NeoButton>
        <NeoButton
          className="mt-2"
          type="button"
          color="white"
          onClick={() => {
            push(`/vehicles`);
          }}
        >
          Cancel
        </NeoButton>
      </div>
    </form>
  );
};
