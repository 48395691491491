// import axiosInstance from "@/app/api";
import {getNeoCookie} from "@/libs/helper/cookies";
import {AxiosResponse} from "axios";
import axiosInstance from "../api";

const onGetData = async (
  userId: any,
  question: any,
  data: CurrentItem
): Promise<any> => {
  try {
    const response = await axiosInstance.post(
      `/execute-chart?userId=${userId}`,
      {
        promptId: data.promptId,
        priorityId: data.priorityId,
        question: question,
        isComplete: data.isComplete,
        chatResponses: data.chatResponses || []
      }
    );
    return response;
  } catch (error) {
    console.error("There was an error fetching the data", error);
    throw error;
  }
};
const onGetInsights = async (userId: any, promptId: any): Promise<any> => {
  try {
    const response = await axiosInstance.get(
      `/insights?userId=${userId}&promptId=${promptId}`
    );
    return response;
  } catch (error) {
    console.error("There was an error fetching the data", error);
    throw error;
  }
};

const onUpdateChartType = async (
  userId: any,
  chartType: ChartTypes,
  data: CurrentItem
): Promise<any> => {
  try {
    const response = await axiosInstance.put(`/updateChart?userId=${userId}`, {
      promptId: data.promptId,
      priorityId: data.priorityId,
      chartType: chartType
    });
    return response;
  } catch (error) {
    console.error("There was an error Updating the data", error);
    throw error;
  }
};

const onUpdateDate = async (
  userId: any,
  fromDate: string,
  toDate: string,
  data: CurrentItem
): Promise<AxiosResponse> => {
  try {
    const response = await axiosInstance.post(`/updateDate?userId=${userId}`, {
      promptId: data.promptId,
      priorityId: data.priorityId,
      fromDate: fromDate, //"yyyy-mm-dd",
      toDate: toDate //"yyyy-mm-dd"
    });
    return response;
  } catch (error) {
    console.error("There was an error Updating the data", error);
    throw error;
  }
};

const onGetList = async (userId: any): Promise<any> => {
  const selectedClient: Client = await getNeoCookie("neo.client");
  try {
    const response = await axiosInstance.get("/default", {
      params: {
        userId: userId
      }
    });
    return response;
  } catch (error) {
    console.error("There was an error fetching the data", error);
    throw error;
  }
};

const onGetVehicleList = async (userId: any): Promise<AxiosResponse> => {
  try {
    const response = await axiosInstance.get("/vehicle-names", {
      params: {
        userId: userId
      }
    });
    return response;
  } catch (error) {
    console.error("There was an error fetching the data", error);
    throw error;
  }
};

const onGetDownloadExcel = async (tableData: any[]): Promise<any> => {
  try {
    const response = await axiosInstance.post("/download/excel", tableData, {
      responseType: "blob" // Specify the response type as blob
    });
    return response;
  } catch (error) {
    console.error("There was an error fetching the data", error);
    throw error;
  }
};

export const dashboardService = {
  onGetData,
  onGetList,
  onGetDownloadExcel,
  onUpdateChartType,
  onGetInsights,
  onUpdateDate,
  onGetVehicleList
};
