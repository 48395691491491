"use client";
import {NeoButton, NeoDatePicker, NeoSelect} from "@/components/forms";
import {NeoSwitchInput} from "@/components/shared/neo-switch/neo-switch-input";
import {AlertFilterConfig} from "@/data/configuration/filter-config";
import {Image, SelectItem} from "@nextui-org/react";
import {useForm} from "react-hook-form";

/**
 * TODO: @selva,@surya,@arun
 * Create a Validation Schema if you have a form & inject them
 */
export const AnnoncementFilter: React.FC<AnnoncementFilterProps> = () => {
  const {register, handleSubmit, setValue} = useForm();

  // Handle switch input change and update form value
  const handleSwitchChange = (name: string, value: boolean) => {
    setValue(name, value);
  };

  const onSubmit = (event: any) => {
    //handle Filter functionality
  };

  const onDateChange = (name: string, event: any) => {
    setValue(name, event);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(event => {
          onSubmit(event);
        })}
      >
        <div className="flex w-[350px] flex-col gap-5 p-2">
          <NeoSelect {...register("alertType")} label="Alert Type">
            {AlertFilterConfig?.alertType?.map(item => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </NeoSelect>
          <NeoDatePicker
            label="Route Time"
            showTime
            handleDateChange={e => onDateChange("routeTime", e)}
          />
          <NeoDatePicker
            label="Reference Trip Range"
            range
            showTime
            handleDateChange={e => onDateChange("refTripRange", e)}
          />
          <NeoSwitchInput
            activeLabel="Active"
            inActiveLabel="Inactive"
            label="Status"
            onValueChange={value => handleSwitchChange("status", value)}
          />
          <div className="w-100 flex justify-end">
            <NeoButton
              type="submit"
              color="yellow"
              className="gap-[5px] text-lg"
              startContent={
                <Image
                  className="h-5 w-5"
                  src="/icons/filter.svg"
                  alt="filter"
                />
              }
            >
              Filter
            </NeoButton>
          </div>
        </div>
      </form>
    </>
  );
};
