"use client";
import {NeoButton} from "@/components/forms";
import {NeoSwitchInput} from "@/components/shared/neo-switch/neo-switch-input";
//import {UsersFilterConfig} from "@/data/users/filter.config";
import {Image} from "@nextui-org/react";
import {useForm} from "react-hook-form";

/**
 * TODO: @selva,@surya,@arun
 * Create a Validation Schema if you have a form & inject them
 */
export const UsersFilter: React.FC<UsersFilterProps> = ({onSubmit}) => {
  const {register, handleSubmit, setValue} = useForm();

  // Handle switch input change and update form value
  const handleSwitchChange = (name: string, value: boolean) => {
    setValue(name, value);
  };

  const onSubmitEvent = (event: any) => {
    //handle Filter functionality
    onSubmit(event);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(event => {
          onSubmitEvent(event);
        })}
      >
        <div className="flex w-[350px] flex-col gap-5 p-2">
          {/* <NeoSelect {...register("userType")} label="User Type">
            {UsersFilterConfig?.userType?.map(item => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </NeoSelect> */}
          {/* <NeoSelect {...register("userRole")} label="User Role">
            {UsersFilterConfig?.userRole?.map(item => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </NeoSelect> */}
          {/* enable for employee login */}
          {/* <NeoSelect {...register("shift")} label="Shift">
            {UsersFilterConfig?.shift?.map(item => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))}
          </NeoSelect> */}
          <div className="grid grid-cols-2 pl-3">
            <NeoSwitchInput
              label="RFID"
              activeLabel="Yes"
              inActiveLabel="No"
              onValueChange={value => handleSwitchChange("rfid", value)}
            />
            {/* enable for employee login */}
            {/* <NeoSwitchInput
              activeLabel="Active"
              inActiveLabel="Inactive"
              label="Status"
              onValueChange={value => handleSwitchChange("status", value)}
            /> */}
            {/* enable for employee login */}
            {/* <NeoSwitchInput
              activeLabel="External"
              inActiveLabel="Internal"
              label="Employment Type"
              onValueChange={value =>
                handleSwitchChange("employmentType", value)
              }
            /> */}
          </div>
          <div className="w-100 flex justify-end">
            <NeoButton
              type="submit"
              color="yellow"
              className="gap-[5px] text-lg"
              startContent={
                <Image
                  className="h-5 w-5"
                  src="/icons/filter.svg"
                  alt="filter"
                />
              }
            >
              Filter
            </NeoButton>
          </div>
        </div>
      </form>
    </>
  );
};
