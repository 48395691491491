export const UsersFilterConfig = {
  userType: [
    {
      label: "Student",
      value: "1"
    },
    {
      label: "Employee",
      value: "2"
    }
  ],
  userRole: [
    {
      label: "Admin",
      value: "1"
    },
    {
      label: "Transport Admin",
      value: "2"
    },
    {
      label: "Parents",
      value: "3"
    }
  ],
  shift: [
    {
      label: "First Shift",
      value: "1"
    },
    {
      label: "Second shift",
      value: "2"
    },
    {
      label: "Night Shift",
      value: "3"
    }
  ],
  gender: [
    {
      label: "Male",
      value: "1"
    },
    {
      label: "Female",
      value: "2"
    },
    {
      label: "Others",
      value: "3"
    }
  ],
  class: [
    {
      label: "I",
      value: "1"
    },
    {
      label: "II",
      value: "2"
    },
    {
      label: "III",
      value: "3"
    },
    {
      label: "IV",
      value: "4"
    },
    {
      label: "V",
      value: "5"
    }
  ]
};
