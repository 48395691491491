import {axiosInstance} from "@/app/api";
import {GPSTrackerStatus, ITrackerStatus, StatusType} from "@/libs/enums/live";
import {
  convertMTOKM,
  convertTOPeriod,
  fetchCurrentStatus,
  fetchFilterStatus,
  fetchTripStatus
} from "@/libs/helper";

const onGetAllVehicles = async (
  institutionUserId: string
): Promise<ILiveVehicles> => {
  const result: ILiveVehicles = {
    meta: {}
  };
  return await axiosInstance()
    .get(`/api/dashboard?userid=${institutionUserId}`)
    .then((response: any) => {
      const neoresponse: NeoResponse<ILiveVehicles> = response.data;
      if (neoresponse.status === "success") {
        result.trackingInfo = neoresponse?.data?.details?.map(
          (row: any, index: number) => {
            const hasTime = row.location && row.location.ti !== undefined;
            const hasRoute =
              row.onTripData && row.onTripData.routeName !== undefined;
            const gpsStatus =
              row.trackerStatus === ITrackerStatus.MOVING
                ? GPSTrackerStatus.MOVING
                : row.trackerStatus === ITrackerStatus.STATIONARY
                  ? GPSTrackerStatus.STATIONARY
                  : row.trackerStatus === ITrackerStatus.OFFLINE && hasTime
                    ? GPSTrackerStatus.OFFLINE
                    : GPSTrackerStatus.DEAD;

            return {
              vehicleId: row.clientId,
              filterStatus: fetchFilterStatus(row.status),
              gpsTrackerStatus: gpsStatus,
              currentStatus: fetchCurrentStatus(gpsStatus, row),
              tripStatus: fetchTripStatus(gpsStatus, hasRoute, row),
              progress: row?.onTripData?.eta?.covered ?? null,
              estimatedTime: row?.onTripData?.eta?.duration ?? null,
              shortName: row?.shortName,
              name: row?.name,
              distance: convertMTOKM(row?.distance) ?? null,
              speed: row?.location?.sp ?? null,
              tripDate: convertTOPeriod(row?.location?.ti) ?? "Unknown",
              driverNumber: row?.driverNumber ?? "",
              driverName: row?.driverName ?? "",
              institutionUserId: institutionUserId,
              location: {
                lat: row?.location?.lat,
                lng: row?.location?.lon,
                content: row?.location?.co
              }
            };
          }
        );
        result.details = neoresponse?.data?.details || [];
        result.meta = {
          totalVehicles: result.trackingInfo?.length || 0,
          inProgressTrips:
            result.trackingInfo?.filter(
              obj => obj.filterStatus === StatusType.INPROGRESS
            ).length || 0,
          completedTrips:
            result.trackingInfo?.filter(
              obj => obj.filterStatus === StatusType.COMPLETED
            ).length || 0,
          notInTrips:
            result.trackingInfo?.filter(
              obj => obj.filterStatus === StatusType.NOTINTRIP
            ).length || 0
        };
        return result;
      } else {
        return result;
      }
    })
    .catch((error: any) => {
      return result;
    });
};

const onGetPlaybackHistory = async (body: PlaybackRequest): Promise<any> => {
  return await axiosInstance()
    .post(`api/vehicle/history`, body)
    .then(response => {
      const neoresponse: NeoResponse<any> = response.data;
      if (neoresponse.status === "success") {
        return {
          playbackData: neoresponse?.data
        };
      } else {
        return {
          playbackData: []
        };
      }
    })
    .catch((error: any) => {
      return {
        playbackData: []
      };
    });
};
export const liveService = {
  onGetAllVehicles,
  onGetPlaybackHistory
};
