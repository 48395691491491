"use client";
import {NeoButton, NeoDatePicker, NeoSelect} from "@/components/forms";
import {NeoSwitchInput} from "@/components/shared/neo-switch/neo-switch-input";
import {UsersFilterConfig} from "@/data/users/filter.config";
import {Image, SelectItem} from "@nextui-org/react";
import {useForm} from "react-hook-form";
/**
 * TODO: @selva,@surya,@arun
 * Create a Validation Schema if you have a form & inject them
 */

export const RoutesFilter: React.FC<RoutesFilterProps> = ({type}) => {
  const {register, handleSubmit, setValue} = useForm();

  const onSubmit = (event: any) => {
    //handle Filter functionality
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(event => {
          onSubmit(event);
        })}
      >
        <div className="flex w-[370px] flex-col gap-5 p-2">
          {type === "details"
            ? routesDetailsFilter({register})
            : manageRoutesFilter({setValue})}
          <div className="w-100 flex justify-end">
            <NeoButton
              type="submit"
              color="yellow"
              className="gap-[5px] text-lg"
              startContent={
                <Image
                  className="h-5 w-5"
                  src="/icons/filter.svg"
                  alt="filter"
                />
              }
            >
              Filter
            </NeoButton>
          </div>
        </div>
      </form>
    </>
  );
};

const routesDetailsFilter = ({register}: any) => {
  return (
    <>
      <NeoSelect {...register("userType")} label="User Type">
        {UsersFilterConfig?.userType?.map(item => (
          <SelectItem key={item.value} value={item.value}>
            {item.label}
          </SelectItem>
        ))}
      </NeoSelect>
      <NeoSelect {...register("shift")} label="Shift">
        {UsersFilterConfig?.shift?.map(item => (
          <SelectItem key={item.value} value={item.value}>
            {item.label}
          </SelectItem>
        ))}
      </NeoSelect>
      <NeoSelect {...register("class")} label="Class">
        {UsersFilterConfig?.class?.map(item => (
          <SelectItem key={item.value} value={item.value}>
            {item.label}
          </SelectItem>
        ))}
      </NeoSelect>
    </>
  );
};

const manageRoutesFilter = ({setValue}: any) => {
  const handleSwitchChange = (name: string, value: boolean) => {
    setValue(name, value);
  };
  const onDateChange = (name: string, event: any) => {
    setValue(name, event);
  };
  return (
    <>
      <NeoDatePicker
        label="Reference Trip Range"
        range
        showTime
        handleDateChange={(e: any) => onDateChange("refTripRange", e)}
      />
      <div className="grid grid-cols-2">
        <NeoSwitchInput
          label="Trip"
          activeLabel="Pickup"
          inActiveLabel="Drop"
          onValueChange={value => handleSwitchChange("trip", value)}
        />
        <NeoSwitchInput
          label="Trip Support"
          activeLabel="Public"
          inActiveLabel="Private"
          onValueChange={value => handleSwitchChange("tripSupport", value)}
        />
        <NeoSwitchInput
          label="Status"
          activeLabel="Active"
          inActiveLabel="Inactive"
          onValueChange={value => handleSwitchChange("status", value)}
        />
      </div>
    </>
  );
};
