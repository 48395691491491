"use client";

import React, {createContext, useContext, useState} from "react";

interface GlobalContextProps {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  currentItem: CurrentItem;
  setCurrentItem: React.Dispatch<React.SetStateAction<CurrentItem>>;
  chatHistoryList: ChatHistory[];
  setChatHistoryList: React.Dispatch<React.SetStateAction<ChatHistory[]>>;
  userId: any;
  setUserId: React.Dispatch<React.SetStateAction<any>>;
  vehiclesList: dropDownValue[];
  setVehiclesList: React.Dispatch<React.SetStateAction<dropDownValue[]>>;
}

interface GlobalContextProviderProps {
  children: React.ReactNode;
}

const GlobalContext = createContext<GlobalContextProps>(
  {} as GlobalContextProps
);

export function GlobalContextProvider({children}: GlobalContextProviderProps) {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<CurrentItem>(
    {} as CurrentItem
  );
  const [chatHistoryList, setChatHistoryList] = useState<ChatHistory[]>([]);
  const [userId, setUserId] = useState<any>(null);
  const [vehiclesList, setVehiclesList] = useState<dropDownValue[]>([]);

  return (
    <GlobalContext.Provider
      value={{
        isOpened,
        setIsOpened,
        currentItem,
        setCurrentItem,
        chatHistoryList,
        setChatHistoryList,
        userId,
        setUserId,
        vehiclesList,
        setVehiclesList
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export const useGlobalContext = () => useContext(GlobalContext);
