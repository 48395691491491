"use client";

import {ChangeMobileForm} from "./change-mobile-form";
import {ChangePasswordForm} from "./change-password-form";

interface ProfileProps {
  formType: string;
  mobileNumber: number;
  onClose: Function;
}

export const ProfileForm: React.FC<ProfileProps> = ({
  formType,
  mobileNumber,
  onClose
}) => {
  if (formType === "change-password") {
    return (
      <ChangePasswordForm
        onClose={onClose}
        mobileNumber={mobileNumber}
      ></ChangePasswordForm>
    );
  } else {
    return (
      <ChangeMobileForm
        onClose={onClose}
        mobileNumber={mobileNumber}
      ></ChangeMobileForm>
    );
  }
};
