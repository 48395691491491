"use client";
import { NeoButton, NeoDatePicker, NeoSelect } from "@/components/forms";
import { NeoSwitchInput } from "@/components/shared/neo-switch/neo-switch-input";
//import {UsersFilterConfig} from "@/data/users/filter.config";
import { Image, SelectItem } from "@nextui-org/react";
import { ChangeEvent, useState,useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNeoContext } from "@/context/neo/neo.context";
/**
 * TODO: @selva,@surya,@arun
 * Create a Validation Schema if you have a form & inject them
 */
export const ReportFilter: React.FC<ReportsFilterProps> = ({ onSubmit, activeFilters,oldFilter }) => {
  const { register, handleSubmit, setValue } = useForm();
  const { vehicles } = useNeoContext();
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    from: null,
    to: null
  });
  //const [selectedFilterType, setSelectedFilterType] = useState("1");
 // const [selectedFilterVehicleOne, setSelectedFilterVehicleOne] = useState();

  const [showDateRange, setShowDateRange] = useState(false);

  const resetFiltervalue  = (filter:number) => {
    if(filter === 1){ // filter Type
      if(oldFilter.filterType){
        if(oldFilter.filterType == 7){  //range 
          setShowDateRange(true);
          if(oldFilter.from && oldFilter.to){
            const fromDate = new Date(oldFilter.fromDate);
            const toDate = new Date(oldFilter.toDate);
            const selectedDateRange = {
              from: fromDate,
              to: toDate
            };
            setSelectedDateRange(selectedDateRange);
          }
        } else {
          handleFromToFilterData(oldFilter.filterType);
        }
        return oldFilter.filterType;
      } else {
        return "1";
      }
    }
    if(filter === 2){ // Vehicle Single Filter
      if(oldFilter.trackerId){
        return oldFilter.trackerId
      } else {
        return vehicles[0].trackerId;
      }
    }
  }
  const reportFilterConfig = {
    mainFilter: [
      {
        label: "Today",
        value: "1"
      },
      {
        label: "Yesterday",
        value: "2"
      },
      {
        label: "Current Week",
        value: "3"
      },
      {
        label: "Last Week",
        value: "4"
      },
      {
        label: "Current Month",
        value: "5"
      },
      {
        label: "Last Month",
        value: "6"
      },
      {
        label: "Range",
        value: "7"
      }
    ]
  }

  const handleFilterTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const filterType = e.target.value;
    setShowDateRange(false);
    handleFromToFilterData(filterType);
    //setSelectedFilterType(filterType);
    setValue('filterType',filterType);
    
  }

  const handleFromToFilterData = (filterType:any) => {
    const currentDate = new Date();
    if (filterType === "1") {  //Today
      const fromDate = new Date(currentDate);
      const toDate = new Date(currentDate);
      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999);
      const selectedDateRange = {
        from: fromDate,
        to: toDate
      };
      setSelectedDateRange(selectedDateRange);

    } else if (filterType === "2") { //Yesterday 
      const fromDate = new Date(currentDate);
      fromDate.setDate(currentDate.getDate() - 1);
      const toDate = new Date(currentDate);
      toDate.setDate(currentDate.getDate() - 1);

      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999);

      const selectedDateRange = {
        from: fromDate,
        to: toDate
      };
      setSelectedDateRange(selectedDateRange);
    } else if(filterType === '3' || filterType === '4'){ //current and last week
      const dayOfWeek = currentDate.getDay(); // 0 (Sun) - 6 (Sat)
      const diffToMonday = (dayOfWeek + 6) % 7; // Days since last Monday
      // Current week
      const currentWeekFrom = new Date(currentDate);
      currentWeekFrom.setDate(currentDate.getDate() - diffToMonday);
      currentWeekFrom.setHours(0, 0, 0, 0);
      
      const currentWeekTo = new Date(currentWeekFrom);
      currentWeekTo.setDate(currentWeekFrom.getDate() + 6);
      currentWeekTo.setHours(23, 59, 59, 999);
      
      //current week shouldnot end last day of the week
      const currentWeekTodayTo = new Date(currentDate);
      currentWeekTodayTo.setHours(23, 59, 59, 999);
      
      // Last week
      const lastWeekFrom = new Date(currentWeekFrom);
      lastWeekFrom.setDate(currentWeekFrom.getDate() - 7);
      
      const lastWeekTo = new Date(currentWeekTo);
      lastWeekTo.setDate(currentWeekTo.getDate() - 7);

      if(filterType === '3'){ //current week
        const selectedDateRange = {
          from: currentWeekFrom,
          to: currentWeekTodayTo
        };
        setSelectedDateRange(selectedDateRange);

      } else if(filterType === '4'){ //lastweek
        const selectedDateRange = {
          from: lastWeekFrom,
          to: lastWeekTo
        }
        setSelectedDateRange(selectedDateRange);
      }
    } else if(filterType === '5'){ //current month
      const currentMonthFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const currentMonthTo = new Date(currentDate);
      currentMonthTo.setHours(23, 59, 59, 999);
      const selectedDateRange = {
        from: currentMonthFrom,
        to: currentMonthTo
      };
      setSelectedDateRange(selectedDateRange);
    } else if(filterType === '6'){ //last month
      const lastMonthFrom = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      const lastMonthTo = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      lastMonthTo.setHours(23, 59, 59, 999);
      const selectedDateRange = {
        from: lastMonthFrom,
        to: lastMonthTo
      };
      setSelectedDateRange(selectedDateRange);
    }else if (filterType === "7") { //selected range
      setShowDateRange(true);
    }
  };
  // Memoize default values for the filters to avoid recomputation on each render
  const defaultFilterType = useMemo(() => resetFiltervalue(1), []);
  const defaultVehicleSingle = useMemo(() => resetFiltervalue(2), []);
  const defaultVehicleMultiple = useMemo(() => resetFiltervalue(3), []);
  
  const onDateChange = (event: any) => {
    setSelectedDateRange(event);
  };
  const onSubmitEvent = (event: any) => {
    //handle Filter functionality
    if (selectedDateRange.from && selectedDateRange.to) {
      let fromDate = new Date(selectedDateRange.from);
      let toDate = new Date(selectedDateRange.to);
      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999);
      event.from = fromDate.getTime();
      event.to = toDate.getTime();
    }
    if (Object.prototype.hasOwnProperty.call(event, 'trackerId')) {
      if(event.trackerId){
        onSubmit(event);
      }
    } else {
      if (Object.prototype.hasOwnProperty.call(event, 'from') && Object.prototype.hasOwnProperty.call(event, 'to')) {
        onSubmit(event);
      }
    }
  };
  return (
    <>
      <form
        onSubmit={handleSubmit(event => {
          onSubmitEvent(event);
        })}
      >
        <div className="flex w-[350px] flex-col gap-5 p-5 dark:bg-neo-black">
          {activeFilters.map((filter: any, index: number) => (
            filter === "date" && (
              <>
                <NeoSelect {...register("filterType")} label="Filter Type"  defaultSelectedKeys={[defaultFilterType]} onChange={handleFilterTypeChange}>
                  {reportFilterConfig?.mainFilter?.map(item => (
                    <SelectItem key={item.value} value={item.value}>
                      {item.label}
                    </SelectItem>
                  ))}
                </NeoSelect>
                {showDateRange && (
                  <NeoDatePicker
                    range={true}
                    label="Date Range"
                    handleDateChange={onDateChange}
                  />
                )}
              </>
            ) || filter === "vehicle" && (
              <NeoSelect {...register("trackerId")} label="Vehicle" selectionMode="multiple" defaultSelectedKeys={[defaultVehicleMultiple]}>
                {vehicles.map(item => (
                  <SelectItem key={item.trackerId} value={item.trackerId}>
                    {item.trackerName}
                  </SelectItem>
                ))}
              </NeoSelect>
            ) || filter === "vehicleSingle" && (
              <NeoSelect {...register("trackerId")} label="Vehicle" defaultSelectedKeys={[defaultVehicleSingle]}>
                {vehicles.map(item => (
                  <SelectItem key={item.trackerId} value={item.trackerId}>
                    {item.trackerName}
                  </SelectItem>
                ))}
              </NeoSelect>
            )
          ))}

          <div className="w-100 flex justify-end">
            <NeoButton
              type="submit"
              color="yellow"
              className="gap-[5px] text-lg"
              startContent={
                <Image
                  className="h-5 w-5"
                  src="/icons/filter.svg"
                  alt="filter"
                />
              }
            >
              Filter
            </NeoButton>
          </div>
        </div>
      </form>
    </>
  );
};
