"use client";
import {NeoModal} from "@/components/@modal/neo.modal";
import {NeoCard, NeoHeader} from "@/components/shared";
import {ReportMenu} from "@/data";
import type {NextPage} from "next";
import {useState} from "react";
import {EyeIcon,InfoIcon} from "@/components/shared";

const reportMenu = ReportMenu as REPORTMEUSCONFIG;
export const Reports: NextPage = function () {
  const [modalOpen, setModalOpen] = useState(false);
  const [menuData,setMenuData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const openPopup = (reportType: any,activereport:any) => {
    setModalOpen(true);
    reportType.activeReport = activereport;
    setMenuData(reportType);
  };

  const handleSearchChange = (e:any) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  return (
    <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
      <div className="mb-4 flex-row px-3">
        <NeoHeader message="Reports"></NeoHeader>
        {/* <input type="text" placeholder="Search Reports..." className="border rounded px-2 py-1 w-64"></input> */}

      </div>
      <div className="p-4">
        <input
          type="text"
          placeholder="Search..."
          className="border rounded px-2 py-1 w-full"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="flex-row">
        <div className="p-3">
          <div className="grid grid-cols-4 gap-4 p-4">
          {reportMenu.map((submenus) => {
              // Filter menus based on the search term
              const filteredMenus = submenus.menus.filter((menus) =>
                menus.menu.toLowerCase().includes(searchTerm)
              );

              // If no menus match the search term, don't render the card
              if (filteredMenus.length === 0) {
                return null;
              }

              return (
                <NeoCard className="col-span-1 p-4" key={submenus.order}>
                  <h2 className="mb-4 text-xl font-semibold">
                    {submenus.header}
                  </h2>
                  <ul className="">
                    {filteredMenus.map((menus, key) => (  menus.url ? (
                      <li
                        key={key}
                        className="flex justify-between cursor-pointer mb-2"
                        onClick={() => openPopup(submenus, menus)}
                        title={menus.menu_description}
                      >
                        {menus.menu}
                        <div className="flex">
                          <EyeIcon />
                          {/* <InfoIcon/> */}
                        </div>
                      </li>) : null
                    )) }
                  </ul>
                </NeoCard>
              );
            })}
          </div>
        </div>
      </div>

      <NeoModal
        onOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        actionType={"report"}
        hasActions={false}
        data = {menuData}
      ></NeoModal>
    </div>
  );
};
