import {DriversFilter, RoutesFilter, UsersFilter, VehiclesFilter} from "@/components/@filter";
import { ReportFilter } from "@/components/@filter/report-filter";
import {NeoTextBox} from "@/components/forms";
import {DownloadIcon, NeoSubHeader, PlusIcon, SearchIcon} from "@/components/shared";
import { useNeoContext } from "@/context/neo/neo.context";
import {useRouter} from "next/navigation";
import {useEffect, useState} from "react";

export const NeoTableHeader: React.FC<NeoTableHeaderProps> = ({
  meta,
  filterValue,
  onClear,
  onSearchChange,
  statusFilter,
  setStatusFilter,
  statusOptions,
  count,
  onRowsPerPageChange,
  onApplyFilters,
  url,
  activeFilters,
  reportRequest,
  reportFilters
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { vehicles } = useNeoContext();

  useEffect(() => {
    if(meta.id === 4 && vehicles.length > 0){ //report
      const currentDate = new Date();
      const fromDate = new Date(currentDate);
      const toDate = new Date(currentDate);
      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999);
      const selectedDateRange = {
        from: fromDate.getTime(),
        to: toDate.getTime()
      };
      reportRequest({trackerId:vehicles[0].trackerId,...selectedDateRange})
    }
  },[vehicles]);
  // arranging key and value seperation
  const transformFilters = (
    filters: Record<string, string | number>
  ): Filter | {} => {
    const filledEntries = Object.entries(filters).filter(
      ([, value]) => value !== ""
    );

    if (filledEntries.length > 0) {
      const filterColumn = filledEntries.map(([key]) => key);
      const filterValues = filledEntries.map(([, value]) => value);
      return {filterColumn, filterValues};
    }

    return {};
  };
  const handleFilterSubmit = (data: any) => {
    const transformedFilters = transformFilters(data);

    onApplyFilters(transformedFilters);
    reportRequest(data);
    setIsPopupOpen(false);
  };
  const {push} = useRouter();
  const renderFilterComponent = (id: any) => {
    switch (id) {
      case 1:
        return <UsersFilter onSubmit={handleFilterSubmit} />;
      case 2:
        return <VehiclesFilter onSubmit={handleFilterSubmit} />;
      case 3:
        return <DriversFilter onSubmit={handleFilterSubmit} />;
      case 4:
        return <ReportFilter onSubmit={handleFilterSubmit} activeFilters={activeFilters} oldFilter={reportFilters} />;
      default:
        return null;
    }
  };
  const handleClick = () => {
    push(url?.page?.add);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
        {meta?.hasFilter && (
          <div className="relative">
            <img
              className="center h-5 w-5 pt-2 "
              src="/icons/Vector.svg"
              onClick={() => setIsPopupOpen(prev => !prev)}
            />
            {isPopupOpen && (
              <div className="absolute left-0 z-50 mt-2 w-auto">
                <div className="rounded-md border bg-white dark:bg-neo-black p-5 shadow-lg">
                  {renderFilterComponent(meta.id)}
                </div>
              </div>
            )}
          </div>
        )}
          <NeoSubHeader message={meta.title}></NeoSubHeader>
        </div>
        <div className="flex justify-between">
          <NeoTextBox
            isClearable
            className="w-full px-5 sm:max-w-[44%] md:max-w-[75%] lg:max-w-[75%] xl:w-[100%]"
            placeholder="Search..."
            startContent={<SearchIcon />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
          {/* <div className="mt-2 px-2">
            <UploadIcon />
          </div> */}
          {meta?.hasCreate && (
            <div className="mt-2 px-2" onClick={handleClick}>
              <PlusIcon />
            </div>
          )}
          {meta?.hasDownload && (<div className="mt-2 px-2">
            <DownloadIcon />
          </div>)}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <span className="text-small text-default-400">
          {meta.description} : {count}
        </span>
        <label className="flex items-center text-small text-default-400">
          Rows per page:
          <select
            className="bg-transparent text-small text-default-400 outline-none"
            onChange={onRowsPerPageChange}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15" selected>
              15
            </option>
            <option value="25">25</option>
            <option value="100">100</option>
          </select>
        </label>
      </div>
    </div>
  );
};

export function capitalize(str: any) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
