"use client";
import {
  NeoButton,
  NeoCheckbox,
  NeoSelect,
  NeoTextBox
} from "@/components/forms";
import {NeoError, NeoHeader} from "@/components/shared";
import {CountriesData} from "@/data/login/countries-data";
import {ILogin} from "@/libs/types/auth";
import {login} from "@/service/login.service";
import {yupResolver} from "@hookform/resolvers/yup";
import {Link, SelectItem} from "@nextui-org/react";
import {ChangeEvent, useEffect, useState} from "react";
import {CircleFlag} from "react-circle-flags";
import {Controller, useForm} from "react-hook-form";
import {AiOutlineLoading} from "react-icons/ai";
import * as yup from "yup";
const defaultValues: ILogin = {
  countryCode: "91",
  userName: "",
  mobileNumber: null,
  password: "",
  rememberMe: false
};
const countries = CountriesData;
export const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [activateMobile, setActivateMobile] = useState(true);
  const [country, setCountry] = useState("in");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    reset();
  }, [activateMobile]);
  const validationSchema = (activateMobile: boolean) =>
    yup.object().shape({
      countryCode:
        activateMobile != true
          ? yup.string()
          : yup.string().required("County code is required"),
      mobileNumber:
        activateMobile != true
          ? yup.number().nullable()
          : yup
              .number()
              .nullable()
              .required("Mobile Number is required")
              .typeError("Mobile number must be a number"),
      userName:
        activateMobile == true
          ? yup.string()
          : yup.string().required("Username is required"),
      password: yup
        .string()
        .required("Password is required")
        .min(1, "Password must be at least 8 characters"),
      rememberMe: yup.boolean()
    });

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: {isSubmitting, errors},
    reset,
    setValue
  } = useForm<ILogin>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema(activateMobile))
  });

  const onSubmit = async (data: ILogin) => {
    const auth = {
      userName: activateMobile
        ? data.countryCode?.concat(String(data?.mobileNumber))
        : data.userName,
      password: data.password,
      rememberMe: data.rememberMe,
      type: activateMobile ? 1 : 2
    };

    setLoading(true);
    try {
      const result = await login(auth);
      if (result?.status === "fail") {
        setError("mobileNumber", {
          type: "server",
          message: "Invalid mobile number"
        });
        setError("password", {
          type: "server",
          message: "Invalid password"
        });
        setErrorMessage(result?.message);
      } else {
        reset();
      }
    } catch (error) {
      setErrorMessage("System Error, Please try again later");
    } finally {
      setLoading(false);
      setTimeout(() => {
        setErrorMessage(null);
      }, 2000);
    }
  };

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue("countryCode", e.target.value);
    const selectedCountry =
      countries.find(x => x.value === e.target.value)?.flag ?? "in";
    setCountry(selectedCountry);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mx-10 w-full px-5">
      <div className="my-5">
        <NeoHeader message="Login"></NeoHeader>
      </div>
      {activateMobile ? (
        <div>
          {/* Mobile Number Field*/}
          <div className="py-2">
            <div className="flex items-center">
              <Controller
                name="countryCode"
                control={control}
                render={({field}) => (
                  <NeoSelect
                    {...field}
                    id="countryCode"
                    labelPlacement="outside"
                    label="Country"
                    placeholder="Select"
                    onChange={handleCountryChange}
                    startContent={
                      <CircleFlag
                        height="25"
                        width="25"
                        countryCode={country}
                      />
                    }
                    className="mr-2 w-1/6"
                  >
                    {countries.map(code => (
                      <SelectItem
                        key={code.value}
                        value={code.value}
                        startContent={
                          <CircleFlag
                            height="25"
                            width="25"
                            countryCode={code.flag}
                          />
                        }
                      >
                        {code.label}
                      </SelectItem>
                    ))}
                  </NeoSelect>
                )}
              />
              <NeoTextBox
                {...register("mobileNumber")}
                id="mobileNumber"
                labelPlacement="outside"
                className="w-5/6"
                label="Mobile Number"
                color={errors.mobileNumber ? "danger" : "default"}
                isInvalid={errors.mobileNumber ? true : false}
                errorMessage={
                  errors.mobileNumber && `${errors.mobileNumber.message}`
                }
                placeholder="Please enter mobile number"
              />
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Link
              className="text-neo-green"
              href="#"
              onClick={e => setActivateMobile(false)}
            >
              Login using username
            </Link>
          </div>
        </div>
      ) : (
        <div>
          {/* Username Field*/}
          <div className="py-2">
            <NeoTextBox
              label="Username"
              id="userName"
              placeholder="Please enter username"
              color={errors.userName ? "danger" : "default"}
              isInvalid={errors.userName ? true : false}
              errorMessage={errors.userName && `${errors.userName.message}`}
              {...register("userName")}
            />
          </div>
          <div className="flex items-center justify-end">
            <Link
              className="text-neo-green"
              href=""
              onClick={e => setActivateMobile(true)}
            >
              Login using Mobile
            </Link>
          </div>
        </div>
      )}

      {/* Password Field*/}
      <div className="py-2">
        <NeoTextBox
          label="Password"
          id="password"
          type="password"
          placeholder="Please enter password"
          color={errors.password ? "danger" : "default"}
          isInvalid={errors.password ? true : false}
          errorMessage={errors.password && `${errors.password.message}`}
          {...register("password")}
        />
      </div>

      {/* RememeberMe Field*/}
      <div className="mt-2 block pb-2">
        <div className="flex items-center justify-between">
          <div className="flex justify-between">
            <NeoCheckbox
              id="rememberMe"
              className="outline-none"
              {...register("rememberMe")}
            >
              Remember me{" "}
            </NeoCheckbox>
          </div>
          <Link className="text-neo-green" href="/auth/forgot-password">
            Forgot password?
          </Link>
        </div>
      </div>
      {errorMessage ? <NeoError message={errorMessage}></NeoError> : null}
      <NeoButton
        className="mt-2 w-full"
        type="submit"
        disabled={isSubmitting}
        isLoading={loading}
        size="xl"
        spinner={
          <AiOutlineLoading size="xs" className="h-6 w-6 animate-spin" />
        }
        color="black"
      >
        Login
      </NeoButton>
    </form>
  );
};
