/**
 *  Axios Interceptor to add  request & response headers
 *  Please restart the server, if you change this file in future
 *  changes won't impact as it is binded on server side
 */

import axios from "axios";

const secret = process.env.AUTH_SECRET;
// const baseURL = 'http://192.168.0.142:8080';
//const baseURL = 'https://ib-generate-reports-api-demo-5i2n5uvnoa-el.a.run.app';
//const baseURL = 'http://139.162.51.221:7374';
//const baseURL = "https://iottest.neotrack.ai:7374";
const baseURL = "https://iottest.neotrack.ai:6868";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  },
  withCredentials: false
});
axiosInstance.interceptors.request.use(
  async request => {
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
