import {Badge} from "@nextui-org/react";
import {Label} from "flowbite-react";
import React from "react";

export const Info: React.FC<InfoProps> = ({data, type}) => {
  return (
    <>
      {(() => {
        switch (type) {
          case "Live": {
            const livedata = data as LiveProps;
            return (
              <div className="p-1">
                <div className="flex flex-col items-center *:!text-black">
                  <Label
                    className="blcok mb-2 text-base"
                    value={livedata.name}
                  />
                  <div className="flex flex-col items-center">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full border-[3px] border-dashed border-red-500">
                      <span className="font-medium">{livedata.speed || 0}</span>
                    </div>
                    <Label
                      className="text-md mb-2 !text-black"
                      value={`kmph`}
                    />
                  </div>
                  <Label value={livedata.date} />
                  <Label
                    className="text-md font-normal"
                    value={`Distance Travelled today : ${livedata.totalDistance}`}
                  />
                </div>
              </div>
            );
          }
          case "Start": {
            return <div></div>;
          }
          case "End": {
            return <div></div>;
          }
          case "Boarding": {
            return (
              <div className="grid min-w-[210px] grid-cols-1 *:!text-black">
                <Label className="text-xl font-medium" value="Boarding Point" />
                <div className="mt-2 *:flex *:w-full">
                  <div className="*:block *:w-2/5">
                    <Label className="font-normal" value="Latitude" />
                    <Label value="11.0205250 " />
                  </div>
                  <div className="*:block *:w-2/5 first:font-normal">
                    <Label className="font-normal" value="Longitude" />
                    <Label value="11.0205250" />
                  </div>
                </div>
                <Label
                  className="mt-1 text-base font-medium"
                  value="Boarding Point Name"
                />
                <Label className="font-normal" value="Stop A" />
              </div>
            );
          }
          case "Speed": {
            const speedData = data as SpeedProps;
            return (
              <div className=" mt-2 flex min-w-[170px] flex-col items-center *:!text-black">
                <div className="flex flex-col items-center">
                  <div className="flex h-10 w-10 items-center justify-center rounded-full border-[3px] border-dashed border-red-500">
                    <span className="font-medium">{speedData.speed || 0}</span>
                  </div>
                  <Label className="text-md mb-2 !text-black" value={`kmph`} />
                </div>
                <Label className="text-base" value={`${speedData.duration}`} />
                <Label
                  className="text-xs !text-blue-500"
                  value={`${speedData.latLng}`}
                />
              </div>
            );
          }
          case "ColorMarker": {
            const colorMarkerInfo = data as ColorStationary;
            return (
              <div className=" mt-2 min-w-[170px] text-nowrap *:flex *:w-full">
                <div className="mb-1 flex flex-col items-center justify-center *:!text-black">
                  <img
                    className="h-8 w-8"
                    src="/icons/repeat.png"
                    alt="duration"
                  />
                  <Label value={`${colorMarkerInfo.diffTime} Min`} />
                </div>
                <div className="flex gap-3 p-1 *:!text-black">
                  <Label
                    className="w-1/5 text-right font-normal"
                    value="From:"
                  />
                  <Label value={`${colorMarkerInfo.staionary_start}`} />
                </div>
                <div className="flex gap-3 p-1 *:!text-black">
                  <Label className="w-1/5 text-right font-normal" value="To:" />
                  <Label value={`${colorMarkerInfo.staionary_end}`} />
                </div>
              </div>
            );
          }
          case "BpMarkerInfo": {
            const bpMarkerInfo = data as any;
            return (
              <div className=" mt-2 min-w-[170px] *:flex *:w-full">
                <div className="mb-1 flex flex-col items-center justify-center *:!text-black">
                  <div>
                    <Badge
                      content={`${bpMarkerInfo.users ? bpMarkerInfo?.users?.length : 0}`}
                      color={"danger"}
                      shape="circle"
                      showOutline={false}
                    >
                      <img
                        className="h-12 w-12"
                        src="/icons/groups.png"
                        alt="duration"
                      />
                    </Badge>
                  </div>
                  <Label
                    className="text-xl font-medium"
                    value={`${bpMarkerInfo.bpName}`}
                  />
                  <Label
                    className="text-xs !text-blue-500"
                    value={`${bpMarkerInfo.lat}, ${bpMarkerInfo.lng}`}
                  />
                  <div className="w-full max-w-[200px] *:!text-black">
                    <Label
                      className="mt-2 block text-left text-base font-medium"
                      value="Users"
                    />
                    {bpMarkerInfo.users && bpMarkerInfo.users.length > 0 ? (
                      <>
                        {bpMarkerInfo?.users?.map(
                          (item: any, index: number) => (
                            <span
                              key={index}
                              className="text-xs"
                            >{`${item.stuName}${index < bpMarkerInfo?.users?.length - 1 ? "," : ""} `}</span>
                          )
                        )}
                      </>
                    ) : (
                      <span>User(s) not available</span>
                    )}
                  </div>
                </div>
              </div>
            );
          }
          default: {
            return <span className="text-neo-red">Error</span>;
          }
        }
      })()}
    </>
  );
};
