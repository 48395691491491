export const AlertFilterConfig = {
  alertType: [
    {
      label: "Movement alert",
      value: "1"
    },
    {
      label: "Distance limit alert",
      value: "2"
    }
  ]
};
