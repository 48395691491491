"use client";
import {
  NeoBreadcrumb,
  NeoHeader,
  NeoLabel,
  NeoSubHeader
} from "@/components/shared";
import {decryptUrl} from "@/libs/helper";
import {vehicleService} from "@/service/vehicle.service";
import {Avatar, Card, CardBody, CardHeader, Spinner} from "@nextui-org/react";
import {Label} from "flowbite-react";
import {useEffect, useState} from "react";
const sKey: string = process.env.NEXT_PUBLIC_NEO_SECRET || "";

interface ManageDriverProps {
  params: PageProps;
}

interface PageProps {
  id: number | string;
  action: string;
  slug: string;
}

interface VehicleProfile {
  name: string;
  userId: string;
  profileUrl: string;
}
export const ViewVehicle: React.FC<ManageDriverProps> = function ({params}) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [vehicleProfile, setVehicleProfile] = useState<VehicleProfile>();

  useEffect(() => {
    const fetchData = async () => {
      const id = await decryptUrl(params.id as string, sKey);
      setLoading(true);
      await vehicleService
        .onGetVehicle(id as string)
        .then((response: any) => {
          setData(response);
          onSetVehicleProfile(response);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, [params]);

  const onSetVehicleProfile = (response: any) => {
    const result = response?.cards?.find(
      (item: any) => item.type === "vehicle"
    );
    const VehicleName = result?.fields?.find(
      (x: any) => x.key === "shortName"
    ).value;
    setVehicleProfile({
      name: VehicleName,
      userId: "",
      profileUrl: ""
    });
  };

  return (
    <>
      {loading ? (
        <Spinner color="default" />
      ) : (
        <div className="flex min-h-[calc(100vh-63px)] w-full flex-col p-5">
          <div className="mb-5 flex-row">
            <NeoHeader message="Vehicle"></NeoHeader>
          </div>
          <div className="mb-5 flex-row">
            <NeoBreadcrumb menus={breadCrumb}></NeoBreadcrumb>
          </div>
          <div className="w-full flex-row">
            {data?.cards?.map((item: any, key: any) => (
              <Card key={key} className="mb-5">
                <CardHeader>
                  <div className="px-3 py-2">
                    <NeoSubHeader message={item.title}></NeoSubHeader>
                  </div>
                </CardHeader>
                <CardBody>
                  {item?.type === "vehicle" && (
                    <div className="w-full p-6">
                      <div className="flex w-full items-center justify-between rounded-md bg-gray-100 dark:bg-neo-black px-5 py-3">
                        <div className="flex items-center gap-2">
                          <Avatar
                            className="h-12 w-12 p-1"
                            radius="sm"
                            src={
                              vehicleProfile?.profileUrl
                                ? vehicleProfile?.profileUrl
                                : "/icons/user.svg"
                            }
                            alt="userIcon"
                          />
                          <div>
                            <Label
                              className="block text-base font-bold"
                              value={vehicleProfile?.name}
                            />
                            <Label
                              className="text-sm"
                              value={
                                vehicleProfile?.userId
                                  ? vehicleProfile?.userId
                                  : "NA"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex-row px-3">
                    <div className="flex flex-wrap">
                      {item.fields.map((field: any, key: any) => {
                        if (field?.isVisible) {
                          return (
                            <div className="mb-5 w-1/4" key={key}>
                              <NeoLabel
                                label={field.label}
                                value={field.value}
                              ></NeoLabel>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const breadCrumb = [
  {
    link: "/vehicles",
    label: "Vehicles"
  },
  {
    link: "",
    label: "View Vehicle Details"
  }
];
// "use client";
// import {
//   NeoBreadcrumb,
//   NeoHeader,
//   NeoLabel,
//   NeoSubHeader
// } from "@/components/shared";
// import {VehicleViewConfig} from "@/data";
// import {OperatorService} from "@/service/operator.service";
// import {vehicleService} from "@/service/vehicle.service";
// import {Card, CardBody, CardHeader} from "@nextui-org/react";
// import {Label} from "flowbite-react";
// import type {NextPage} from "next";
// import Image from "next/image";
// import {useEffect, useState} from "react";

// export const ViewVehicle: NextPage = function ({params}) {
//   const dataSource = VehicleViewConfig; //TO DO need to change type defintion
//   const [trackerdata, SetTrackerData] = useState([]);
//   const [Categories, SetCategories] = useState([]);
//   const [Drivers, SetDrivers] = useState([]);
//   const [Operators, SetOperators] = useState([]);

//  return <>Vehicles</>

// useEffect(() => {
//   const categoriearray = {};
//   const operatorarray = {};
//   const driverarray = {};

//   const fetchOperatorData = async () => {
//     const instituteId = 39;
//     const getoperatordatas =
//       await OperatorService.onGetOperators(instituteId);
//     if (getoperatordatas) {
//       Object.keys(getoperatordatas).forEach(function (key, value) {
//         /* Categories Data Grouping */
//         if (key == "categories" && getoperatordatas[key]) {
//           const categoriedatas = getoperatordatas[key];
//           Object.values(categoriedatas).forEach(function (value) {
//             categoriearray[value.categoryId] = value.categoryName;
//           });
//           SetCategories(categoriearray);
//         }

//         /* Operator & Driver Data Grouping */
//         if (key == "operators" && getoperatordatas[key]) {
//           const operatordatas = getoperatordatas[key];
//           Object.values(operatordatas).forEach(function (value) {
//             operatorarray[value.operatorId] = value.operatorName;
//             /* Driver Data Grouping */
//             if (value.drivers) {
//               Object.values(value.drivers).forEach(function (value) {
//                 driverarray[value.driverId] = value.name;
//               });
//             }
//           });
//           SetOperators(operatorarray);
//           SetDrivers(driverarray);
//         }
//       });
//     }
//   };

//   const fetchVehicleData = async () => {
//     const vehicledata = await vehicleService.onGetVehicle(data.id);
//     SetTrackerData(vehicledata);
//   };

//   fetchVehicleData();
//   fetchOperatorData();
// }, []);

// const vehiclemenu = [
//   {
//     link: "/vehicles",
//     label: "Vehicle"
//   },
//   {
//     link: "",
//     label: trackerdata.name
//   },
//   {
//     link: "",
//     label: "View Vehicle"
//   }
// ];

// return (
//   <div className="flex min-h-[calc(100vh-63px)] w-full flex-col p-5">
//     <div className="mb-5 flex-row">
//       <NeoHeader message="Vehicles"></NeoHeader>
//     </div>
//     <div className="mb-5 flex-row">
//       <NeoBreadcrumb menus={vehiclemenu}></NeoBreadcrumb>
//     </div>
//     <div className="w-full flex-row">
//       {dataSource.cards.map((item: any, key: any) => (
//         <Card key={key} className="mb-5">
//           <CardHeader>
//             <div className="px-3 py-2">
//               <NeoSubHeader message={item.title}></NeoSubHeader>
//             </div>
//           </CardHeader>
//           <CardBody>
//             {(() => {
//               if (key == 0) {
//                 return (
//                   <div className="grid w-full grid-cols-10 gap-5 rounded-lg bg-gray-100 p-3">
//                     <div className="col-span-4 py-2 lg:col-span-1">
//                       <Image
//                         alt="Neo Track"
//                         priority
//                         src="../../images/Ashok_Leyland_logo.svg"
//                         height={100}
//                         width={150}
//                       />
//                     </div>
//                     <div className="col-span-4 py-3 lg:col-span-4">
//                       <h2 className="font-bold">{trackerdata.?shortName}</h2>
//                       <h4>
//                         {trackerdata?.regNo} &#10072;{" "}
//                         {Drivers[trackerdata?.driver]
//                           ? Drivers[trackerdata?.driver]
//                           : trackerdata?.driver}
//                       </h4>
//                     </div>
//                   </div>
//                 );
//               }
//             })()}
//             <div className="grid w-full grid-cols-4 gap-5">
//               {item.fields.map((field: any, key: any) => (
//                 <div className="col-span-4 py-2 lg:col-span-1" key={key}>
//                   {(() => {
//                     const keyid = field.key;
//                     const keyvalue = trackerdata[keyid];
//                     if (keyid == "colour") {
//                       return (
//                         <>
//                           <Label
//                             className="mb-2 block text-gray-500"
//                             value={field.label}
//                           />
//                           <div
//                             className="w-0.5 rounded-lg border p-3"
//                             style={{backgroundColor: "#" + keyvalue}}
//                           ></div>
//                         </>
//                       );
//                     } else if (keyid == "gps") {
//                       return (
//                         <>
//                           <NeoLabel
//                             label={field.label}
//                             value={keyvalue ? "Enabled" : "Disabled"}
//                           />
//                         </>
//                       );
//                     } else if (keyid == "category") {
//                       return (
//                         <>
//                           <NeoLabel
//                             label={field.label}
//                             value={
//                               Categories[keyvalue]
//                                 ? Categories[keyvalue]
//                                 : "N/A"
//                             }
//                           />
//                         </>
//                       );
//                     } else if (keyid == "operator") {
//                       return (
//                         <>
//                           <NeoLabel
//                             label={field.label}
//                             value={
//                               Operators[keyvalue]
//                                 ? Operators[keyvalue]
//                                 : "N/A"
//                             }
//                           />
//                         </>
//                       );
//                     } else if (keyid == "driver") {
//                       return (
//                         <>
//                           <NeoLabel
//                             label={field.label}
//                             value={
//                               Drivers[keyvalue] ? Drivers[keyvalue] : "N/A"
//                             }
//                           />
//                         </>
//                       );
//                     } else {
//                       return (
//                         <>
//                           <NeoLabel
//                             label={field.label}
//                             value={keyvalue ? keyvalue : "N/A"}
//                           ></NeoLabel>
//                         </>
//                       );
//                     }
//                   })()}
//                 </div>
//               ))}
//             </div>
//           </CardBody>
//         </Card>
//       ))}
//     </div>
//   </div>
// );
//};
