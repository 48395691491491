"use client";

import {NeoModal} from "@/components/@modal/neo.modal";
import {
  MenuIcon,
  NeoCard,
  NeoHeader,
  NeoLabel,
  NeoSubHeader
} from "@/components/shared";
import {profileService} from "@/service/profile.service";
import {
  Button,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from "@nextui-org/react";
import type {NextPage} from "next";
import router from "next/router";
import {useEffect, useState} from "react";

export const Profile: NextPage = function () {
  const [modalOpen, setModalOpen] = useState(false);

  const handleAction = (key: any) => {
    const item = data?.actions.find((x: ProfileModule.Action) => x.key === key);
    if (!item?.action) {
      router.push(item.link);
    } else {
      setActionType(item.action);
      setModalOpen(true);
    }
  };

  const [data, setData] = useState<any>();
  const [actionType, setActionType] = useState<string>("");
  useEffect(() => {
    const fetchData = async () => {
      const results = await profileService.onGetProfile();
      setData(results);
    };
    fetchData();
  }, [data]);

  return (
    <>
      <div className="flex min-h-[calc(100vh-63px)] w-full flex-col px-10 py-10">
        <div className="mb-4 flex-row px-3">
          <NeoHeader message={data?.title}></NeoHeader>
        </div>
        <div className="flex-row">
          <div className="w-1/2 flex-col">
            <NeoCard>
              <CardHeader>
                <div className="flex w-full justify-between">
                  <div className="flex w-[90%] px-2">
                    <NeoSubHeader message={data?.card?.title}></NeoSubHeader>
                  </div>
                  <div className="ml-auto w-[10%] pt-1">
                    <Dropdown>
                      <DropdownTrigger>
                        <Button isIconOnly size="sm" variant="light">
                          <MenuIcon />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        items={data?.actions}
                        onAction={(key: any) => handleAction(key)}
                      >
                        {data?.actions?.map((item: any) => (
                          <DropdownItem key={item.key}>
                            {item.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="flex-row px-3">
                  <div className="flex flex-wrap">
                    {data?.card?.fields?.map((item: any, key: any) => (
                      <div className="mb-5 w-1/2" key={key}>
                        <NeoLabel
                          label={item.label}
                          value={item.value}
                        ></NeoLabel>
                      </div>
                    ))}
                  </div>
                </div>
              </CardBody>
            </NeoCard>
          </div>
        </div>
      </div>
      <NeoModal
        actionType={actionType}
        onOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        hasActions={false}
        rowData={undefined}
      ></NeoModal>
    </>
  );
};

export async function getServerSideProps() {
  const data = await profileService.onGetProfile();
  return {
    props: {
      data
    }
  };
}
