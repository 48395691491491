"use client";

import {NeoButton, NeoDatePicker, NeoTextBox} from "@/components/forms";
import {navigateToEditPage} from "@/libs/helper";
import {vehicleService} from "@/service/vehicle.service";
import {yupResolver} from "@hookform/resolvers/yup";
import {useRouter} from "next/navigation";
import {useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {AiOutlineLoading} from "react-icons/ai";
import {IoIosArrowBack} from "react-icons/io";
import * as yup from "yup";

interface IVehicleComplianceForm {
  regCertificateNo?: string;
  regCertificateExp?: any;
  pollutionCertificateNo?: string;
  pollutionCertificateExp?: any;
  firstPartyInsuranceNo?: string;
  firstPartyInsuranceExp?: any;
  thirdPartyInsuranceNo?: string;
  thirdPartyInsuranceExp?: any;
  permitNo?: string;
  permitExp?: any;
  fitnessExp?: any;
  roadTaxExp?: any;
  lastServiceKM?: string;
  lastServiceOn?: any;
  lastTyreChangeOn?: any;
}
type VehicleComplianceFormProps = {
  items: any;
  client: Client;
};
export const VehicleComplianceForm: React.FC<VehicleComplianceFormProps> = ({
  items,
  client
}) => {
  const {push} = useRouter();
  const [loading, setLoading] = useState(false);
  const selectedClient = useRef(client);
  const [defaultValues, setDefaultvalue] = useState(items?.data);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const validationSchema = yup.object().shape({
    regCertificateNo: yup.string(),
    regCertificateExp: yup
      .date()
      .nullable()
      .typeError("Please Enter the Valid Date")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    pollutionCertificateNo: yup.string(),
    pollutionCertificateExp: yup
      .date()
      .nullable()
      .typeError("Please Enter the Valid Date")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    firstPartyInsuranceNo: yup.string(),
    firstPartyInsuranceExp: yup
      .date()
      .nullable()
      .typeError("Please Enter the Valid Date")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    thirdPartyInsuranceNo: yup.string(),
    thirdPartyInsuranceExp: yup
      .date()
      .nullable()
      .typeError("Please Enter the Valid Date")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    permitNo: yup.string(),
    permitExp: yup
      .date()
      .nullable()
      .typeError("Please Enter the Valid Date")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    fitnessExp: yup
      .date()
      .nullable()
      .typeError("Please Enter the Valid Date")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    roadTaxExp: yup
      .date()
      .nullable()
      .typeError("Please Enter the Valid Date")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    lastServiceKM: yup.string(),
    lastServiceOn: yup
      .date()
      .nullable()
      .typeError("Please Enter the Valid Date")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      }),
    lastTyreChangeOn: yup
      .date()
      .nullable()
      .typeError("Please Enter the Valid Date")
      .transform((value, originalValue) => {
        return originalValue === "" ? null : value;
      })
  });
  const {
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    setValue,
    setError
  } = useForm<IVehicleComplianceForm>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validationSchema)
  });
  const onDateChange = (name: any, event: any) => {
    setValue(name, event);
    event && setError(name, {type: "setValueAs", message: ""});
  };
  const onSubmit = async (data: any) => {
    setLoading(true);
    const additionalPrimaryData = {
      insId: selectedClient.current.institutionId,
      trackerId: items?.trackerId,
      currentUser: selectedClient.current.institutionUserId
    };
    await vehicleService
      .onEditVehicle(
        "api/vehicles/update",
        data,
        additionalPrimaryData,
        "vehicle-compliance"
      )
      .then(response => {
        if (response?.data?.status === "success") {
          push(`/vehicles`);
        } else {
          setErrorMessage(
            "Sorry!, We have technical issue please try again later"
          );
        }
      })
      .catch(err => {
        if (err?.errors.length > 0) {
          err?.errors.forEach(
            ({field, message}: {field: any; message: string}) => {
              setError(field, {type: "server", message: message});
            }
          );
        }
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      });
  };
  const backToForm = async () => {
    const route: any = await navigateToEditPage(
      "vehicles",
      items.trackerId,
      "vehicle"
    );
    if (route) push(route);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full px-5">
      <div className="grid w-full grid-cols-4 gap-9">
        <NeoTextBox
          label="Registration Certificate No"
          id="regCertificateNo"
          defaultValue={defaultValues?.regCertificateNo}
          placeholder="Please Enter RC No"
          color={errors.regCertificateNo ? "danger" : "default"}
          isInvalid={errors.regCertificateNo ? true : false}
          errorMessage={
            errors.regCertificateNo && `${errors.regCertificateNo.message}`
          }
          {...register("regCertificateNo")}
        />

        <NeoDatePicker
          label="Registration Certificate Expiry"
          placeholder="Reg Certificate Exp"
          value={defaultValues?.regCertificateExp}
          errorMessage={errors.regCertificateExp?.message}
          handleDateChange={e => onDateChange("regCertificateExp", e)}
        />
        <NeoTextBox
          label="Pollution Certificate No"
          id="pollutionCertificateNo"
          defaultValue={defaultValues?.pollutionCertificateNo}
          placeholder="Please Enter Pollution Certificate No"
          color={errors.pollutionCertificateNo ? "danger" : "default"}
          isInvalid={errors.pollutionCertificateNo ? true : false}
          errorMessage={
            errors.pollutionCertificateNo &&
            `${errors.pollutionCertificateNo.message}`
          }
          {...register("pollutionCertificateNo")}
        />

        <NeoDatePicker
          label="Pollution Certificate Expiry"
          placeholder="Pollution Certificate Exp"
          value={defaultValues?.pollutionCertificateExp}
          errorMessage={errors.pollutionCertificateExp?.message}
          handleDateChange={e => onDateChange("pollutionCertificateExp", e)}
        />

        <NeoTextBox
          label="First Party Insurance No"
          id="firstPartyInsuranceNo"
          defaultValue={defaultValues?.firstPartyInsuranceNo}
          placeholder="Please Enter firstParty Insurance No"
          color={errors.firstPartyInsuranceNo ? "danger" : "default"}
          isInvalid={errors.firstPartyInsuranceNo ? true : false}
          errorMessage={
            errors.firstPartyInsuranceNo &&
            `${errors.firstPartyInsuranceNo.message}`
          }
          {...register("firstPartyInsuranceNo")}
        />

        <NeoDatePicker
          label={"First Party Insurance Exp"}
          placeholder="First Party Insurance Exp"
          value={defaultValues?.firstPartyInsuranceExp}
          errorMessage={errors.firstPartyInsuranceExp?.message}
          handleDateChange={e => onDateChange("firstPartyInsuranceExp", e)}
        />

        <NeoTextBox
          label="Third Party Insurance No"
          id="thirdPartyInsuranceNo"
          defaultValue={defaultValues?.thirdPartyInsuranceNo}
          placeholder="Please Enter firstParty Insurance No"
          color={errors.thirdPartyInsuranceNo ? "danger" : "default"}
          isInvalid={errors.thirdPartyInsuranceNo ? true : false}
          errorMessage={
            errors.thirdPartyInsuranceNo &&
            `${errors.thirdPartyInsuranceNo.message}`
          }
          {...register("thirdPartyInsuranceNo")}
        />

        <NeoDatePicker
          label="Third Party Insurance Exp"
          placeholder="Third Party Insurance Exp"
          value={defaultValues?.thirdPartyInsuranceExp}
          errorMessage={errors.thirdPartyInsuranceExp?.message}
          handleDateChange={e => onDateChange("thirdPartyInsuranceExp", e)}
        />

        <NeoTextBox
          label="PermitNo"
          id="permitNo"
          defaultValue={defaultValues?.permitNo}
          placeholder="Please Enter PermitNo"
          color={errors.permitNo ? "danger" : "default"}
          isInvalid={errors.permitNo ? true : false}
          errorMessage={errors.permitNo && `${errors.permitNo.message}`}
          {...register("permitNo")}
        />

        <NeoDatePicker
          label="Permit Exp"
          placeholder="Permit Exp"
          value={defaultValues?.permitExp}
          errorMessage={errors.permitExp?.message}
          handleDateChange={e => onDateChange("permitExp", e)}
        />

        <NeoDatePicker
          label="Fitness Exp"
          placeholder="Fitness Exp"
          value={defaultValues?.fitnessExp}
          errorMessage={errors.fitnessExp?.message}
          handleDateChange={e => onDateChange("fitnessExp", e)}
        />

        <NeoDatePicker
          label="Road Tax Exp"
          placeholder="Road Tax Exp"
          value={defaultValues?.roadTaxExp}
          errorMessage={errors.roadTaxExp?.message}
          handleDateChange={e => onDateChange("roadTaxExp", e)}
        />

        {/* <NeoTextBox
          label="Last Service KM"
          id="lastServiceKM"
          defaultValue={defaultValues?.lastServiceKM}
          placeholder="Please Enter LastServiceKM"
          color={errors.lastServiceKM ? "danger" : "default"}
          isInvalid={errors.lastServiceKM ? true : false}
          errorMessage={
            errors.lastServiceKM && `${errors.lastServiceKM.message}`
          }
          {...register("lastServiceKM")}
        /> */}

        <NeoDatePicker
          label="Last Service On"
          placeholder="Last Service On"
          value={defaultValues?.lastServiceOn}
          errorMessage={errors.lastServiceOn?.message}
          handleDateChange={e => onDateChange("lastServiceOn", e)}
        />

        <NeoDatePicker
          label="Last Tyre Change On"
          placeholder="Last Tyre Change On"
          value={defaultValues?.lastTyreChangeOn}
          errorMessage={errors.lastTyreChangeOn?.message}
          handleDateChange={e => onDateChange("lastTyreChangeOn", e)}
        />
      </div>
      <div className="flex items-center justify-end p-4">
        <NeoButton
          onClick={() => backToForm()}
          isIconOnly
          variant="solid"
          className="mt-2"
        >
          <IoIosArrowBack size={25} />
        </NeoButton>
        <NeoButton
          className="mx-5 mt-2"
          type="submit"
          disabled={isSubmitting}
          isLoading={loading}
          spinner={
            <AiOutlineLoading size="xs" className="h-6 w-6 animate-spin" />
          }
          color="black"
        >
          Submit
        </NeoButton>
        <NeoButton
          className="mt-2"
          type="button"
          color="white"
          onClick={() => {
            push(`/vehicles`);
          }}
        >
          Cancel
        </NeoButton>
      </div>
    </form>
  );
};
// function useRef(client: any) {
//   throw new Error("Function not implemented.");
// }
