import {Pagination} from "@nextui-org/react";
import {TableFooterProps} from "../../../types/neo-table";

export const NeoTableFooter: React.FC<TableFooterProps> = ({
  meta,
  hasSearchFilter,
  page,
  totalPages,
  setPage,
  selectedKeys,
  items
}) => {
  return (
    <div className="flex items-center justify-between px-2 py-2">
      {meta.hasPagination && (
        <Pagination
          showControls
          classNames={{
            cursor: "bg-foreground text-background"
          }}
          color="default"
          isDisabled={hasSearchFilter}
          page={page}
          total={totalPages}
          variant="light"
          onChange={setPage}
        />
      )}
      {meta.hasCheckbox && (
        <span className="text-small text-default-400">
          {selectedKeys === "all"
            ? "All items selected"
            : `${selectedKeys.size} of ${items.length} selected`}
        </span>
      )}
    </div>
  );
};
